import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Box,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import JessibucaPlayer from "react-jessibuca";

const ImageGalleryModal = ({ isOpen, onClose, video }) => {
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const toast = useToast();

  useEffect(() => {
    setIsLoading(true);
  }, [video]); // Reset loading state when a new video is provided

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setIsLoading(false);
    toast({
      title: "Video Load Failed",
      description: "The video failed to load.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  };
  console.log(`https://media2.arcisai.io:5080/mp4/${video}`);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Video Gallery</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {video ? (
            <Box>
              {isLoading && <Spinner />}

              <video
                crossOrigin="anonymous"
                preload="auto"
                src={`https://media2.arcisai.io:5080/mp4/${video}`}
                onPlay={handleLoad}
                onError={handleError}
                controls
                width="100%"
                height="500px"
              />
            </Box>
          ) : (
            <Text>No video available.</Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImageGalleryModal;
