import React, { useState } from 'react';
import styles from './Settings.module.css';
import { IoSettingsOutline, IoWifi } from "react-icons/io5";
import { LuBadgeHelp } from "react-icons/lu";
import { MdContentCopy } from "react-icons/md";
import MotionDetection from "./Ai-Settings/MotionDetection";
import HumanDetection from "./Ai-Settings/HumanDetection";
import FaceDetection from "./Ai-Settings/FaceDetection";
import LineCrossingDetection from "./Ai-Settings/LineCrossingDetection";
import AreaDetection from "./Ai-Settings/AreaDetection";
import CustomerTrafficStats from "./Ai-Settings/CustomerTrafficStats";
import UnattendBaggageDetection from "./Ai-Settings/UnattendBaggageDetection";
import MissingObjectDetection from "./Ai-Settings/MissingObjectDetection";
import { Box } from '@chakra-ui/react';
import MobileViewAIsettings from './Ai-Settings/MobileViewAISettings';

function EdgeAi({ selectedAi, device, onSave }) {
  return (
    
    <>
      <Box display={{ base: 'none', md: 'block' }}>
        {selectedAi === "Motion Detection" && (
        <MotionDetection deviceId={device} />
      )}
      {selectedAi === "Human Detection" && (
        <HumanDetection deviceId={device} />
      )}
      {selectedAi === "Face Detection" && (
        <FaceDetection deviceId={device} />
      )}
      {selectedAi === "Line Crossing Detection" && (
        <LineCrossingDetection deviceId={device} />
      )}
      {selectedAi === "Area Detection" && <AreaDetection deviceId={device} />}
      {selectedAi === "Traffic Statistics" && (
        <CustomerTrafficStats deviceId={device} />
      )}
      {selectedAi === "Unattended Baggage Detection" && (
        <UnattendBaggageDetection deviceId={device} />
      )}
      {selectedAi === "Missing Object Detection" && (
        <MissingObjectDetection deviceId={device} />
        )}
        
        </Box>

        <div style={{ display: window.innerWidth >= 768 ? 'none' : 'block' }}> 
  <MobileViewAIsettings deviceId={device} />
</div>

    </>


  );
}

export default EdgeAi;