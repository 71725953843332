import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styles from '../Settings.module.css';
import { Box, VStack, Text, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Checkbox, FormControl, FormLabel, HStack, Select } from "@chakra-ui/react";
import { getIrSetup, getVideoSettings, postIrSetup, postVideoSettings } from '../../actions/settingsActions';

function Image_Setup({ deviceId, onSave }) {
  const [contrast, setContrast] = useState(50);
  const [brightness, setBrightness] = useState(50);
  const [saturation, setSaturation] = useState(50);
  const [hue, setHue] = useState(50);
  const [sharpen, setSharpen] = useState(50);
  const [flipChecked, setFlipChecked] = useState(false);
  const [mirrorChecked, setMirrorChecked] = useState(false);
  const [error, setError] = useState(null);

  const [irMode, setIrMode] = useState(); // Default value



  // Fetch the current video settings when the component mounts



  const fetchIrMode = async () => {
    try {
      const response = await getIrSetup(deviceId);
      const currentIrMode = response.data.irCutFilter.irCutMode;
      setIrMode(currentIrMode);
    } catch (error) {
      console.error('Error fetching IR mode:', error.response ? error.response.data : error.message);
    }
  };

  // get video settings
  const fetchSettings = async () => {
    try {
      const response = await getVideoSettings(deviceId);
      setContrast(response.contrastLevel);
      setBrightness(response.brightnessLevel);
      setSaturation(response.saturationLevel);
      setHue(response.hueLevel);
      setSharpen(response.sharpnessLevel);
      setFlipChecked(response.flipEnabled);
      setMirrorChecked(response.mirrorEnabled);
    } catch (error) {
      console.error('Error fetching settings:', error);
      setError(error);
    }
  };
  useEffect(() => {
    fetchIrMode();
    fetchSettings();
  }, []);

  // post video settings
  const updateVideoSettings = async (updatedSettings) => {
    try {
      const response = await postVideoSettings(deviceId, updatedSettings);
      fetchSettings();
      // console.log('Settings updated:', response.data);
    } catch (error) {
      console.error('Error updating settings:', error);
      setError(error);
    }
  };

  const handleSettingChange = (key, value) => {
    const updatedSettings = {
      contrastLevel: key === 'contrast' ? value : contrast,
      brightnessLevel: key === 'brightness' ? value : brightness,
      saturationLevel: key === 'saturation' ? value : saturation,
      hueLevel: key === 'hue' ? value : hue,
      sharpnessLevel: key === 'sharpen' ? value : sharpen,
      flipEnabled: key === 'flipChecked' ? value : flipChecked,
      mirrorEnabled: key === 'mirrorChecked' ? value : mirrorChecked,
    };

    // Update the settings in state
    if (key === 'contrast') setContrast(value);
    if (key === 'brightness') setBrightness(value);
    if (key === 'saturation') setSaturation(value);
    if (key === 'hue') setHue(value);
    if (key === 'sharpen') setSharpen(value);
    if (key === 'flipChecked') setFlipChecked(value);
    if (key === 'mirrorChecked') setMirrorChecked(value);

    // Send updated settings to the server
    updateVideoSettings(updatedSettings);
  };

  useEffect(() => {
    if (onSave) {
      // Pass a default value to handleIrModeChange when called without an event
      onSave(() => handleIrModeChange({ target: { value: irMode } }));
    }
  }, [onSave, irMode]);


  const handleIrModeChange = async (event) => {
    // Check if event exists to prevent undefined access
    const selectedValue = event?.target?.value || irMode; // Fallback to current irMode if no event

    setIrMode(selectedValue); // Update the state with the new selection

    const imageConfig = {
      irCutFilter: {
        irCutMode: selectedValue,
      },
    };

    try {
      const response = await postIrSetup(deviceId, imageConfig);
      // console.log('postIrSetup:', response.data);
      fetchIrMode();
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <Box className={styles.notify} marginLeft={'0px'} mt={{ base: '10', md: 0 }}>
      <VStack align="start" spacing={3} mb={2} color="#7F56D9">
        <Text fontSize={{ base: '20px', md: '24px' }} fontWeight="bold">
          Video Setting
        </Text>
      </VStack>



      {/* IR Mode selection */}
      <HStack spacing={3} mb={3} align="center" width="full">
        <Text flex="0 0 150px" fontWeight="bold">
          IR Mode
        </Text>
        <Select
          value={irMode}
          onChange={handleIrModeChange}
          width={{ base: '150px', md: '200px' }} // Responsive width
          fontSize={{ base: '14px', md: '16px' }} // Responsive font size
        >
          <option value="auto">Auto</option>
          <option value="light">Light Mode</option>
          <option value="smart">Smart Mode</option>
          <option value="daylight">Daylight Mode</option>
          <option value="night">Night Mode</option>
        </Select>
      </HStack>

      {/* Contrast */}
      <HStack spacing={2} mb={3} width="full" align="center">
        <Text fontSize={{ base: '15px', md: '16px' }} fontWeight="bold" minWidth={{ base: '70px', md: '150px' }}>
          Contrast
        </Text>
        <input
          type="range"
          min="0"
          max="100"
          value={contrast}
          onChange={(e) => handleSettingChange('contrast', parseInt(e.target.value, 10))}
          style={{ flex: 1 }}
        />
      </HStack>

      {/* Brightness */}
      <HStack spacing={2} mb={3} width="full" align="center">
        <Text fontSize={{ base: '15px', md: '16px' }} fontWeight="bold" minWidth={{ base: '70px', md: '150px' }}>
          Brightness
        </Text>
        <input
          type="range"
          min="0"
          max="100"
          value={brightness}
          onChange={(e) => handleSettingChange('brightness', parseInt(e.target.value, 10))}
          style={{ flex: 1 }}
        />
      </HStack>

      {/* Saturation */}
      <HStack spacing={2} mb={3} width="full" align="center">
        <Text fontSize={{ base: '15px', md: '16px' }} fontWeight="bold" minWidth={{ base: '70px', md: '150px' }}>
          Saturation
        </Text>
        <input
          type="range"
          min="0"
          max="100"
          value={saturation}
          onChange={(e) => handleSettingChange('saturation', parseInt(e.target.value, 10))}
          style={{ flex: 1 }}
        />
      </HStack>

      {/* Hue */}
      <HStack spacing={2} mb={3} width="full" align="center">
        <Text fontSize={{ base: '15px', md: '16px' }} fontWeight="bold" minWidth={{ base: '70px', md: '150px' }}>
          Hue
        </Text>
        <input
          type="range"
          min="0"
          max="100"
          value={hue}
          onChange={(e) => handleSettingChange('hue', parseInt(e.target.value, 10))}
          style={{ flex: 1 }}
        />
      </HStack>

      {/* Sharpen */}
      <HStack spacing={2} mb={3} width="full" align="center">
        <Text fontSize={{ base: '15px', md: '16px' }} fontWeight="bold" minWidth={{ base: '70px', md: '150px' }}>
          Sharpen
        </Text>
        <input
          type="range"
          min="0"
          max="100"
          value={sharpen}
          onChange={(e) => handleSettingChange('sharpen', parseInt(e.target.value, 10))}
          style={{ flex: 1 }}
        />
      </HStack>

      {/* Flip */}
      <HStack spacing={2} mb={3} width="full" align="center">
        <Text fontSize={{ base: '15px', md: '16px' }} fontWeight="bold" minWidth={{ base: '70px', md: '150px' }}>
          Flip
        </Text>
        <input
          type="checkbox"
          checked={flipChecked}
          onChange={(e) => handleSettingChange('flipChecked', e.target.checked)}
        />
      </HStack>

      {/* Mirror */}
      <HStack spacing={2} mb={3} width="full" align="center">
        <Text fontSize={{ base: '15px', md: '16px' }} fontWeight="bold" minWidth={{ base: '70px', md: '150px' }}>
          Mirror
        </Text>
        <input
          type="checkbox"
          checked={mirrorChecked}
          onChange={(e) => handleSettingChange('mirrorChecked', e.target.checked)}
        />
      </HStack>

      {error && <Text color="red.500" mt={2} fontSize="12px">Error: {error.message}</Text>}
    </Box>


  );
}

export default Image_Setup;