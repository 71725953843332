import React, { useState } from 'react';
import styles from './EventCard.module.css';
import { FiCopy, FiCheck } from 'react-icons/fi'; // Import copy and check icons
import { useClipboard } from '@chakra-ui/react';  // Import Chakra's useClipboard

const EventCard = ({ title, deviceId, length, name, source, imageUrl, TimeStart, TimeEnd, onUrlSelect }) => {
  const { hasCopied, onCopy } = useClipboard(deviceId);  // Chakra's useClipboard hook
  const [selectedUrl, setSelectedUrl] = useState(null);

  const handlePlay = (item) => {
    setSelectedUrl(item);
    if (onUrlSelect) {
      onUrlSelect(item); // Call the callback function passed from the parent
    }
  };

  return (
    <article className={styles.eventCard}>
      <span className={styles.duration}>{length}</span>
      <img
        onClick={() => handlePlay(source === "Cloud" ? imageUrl : `https://${deviceId}.torqueverse.dev/flv/pb?pb_${TimeStart}_${TimeEnd}_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`)}
        src={imageUrl}
        alt={`Event at ${title}`}
        style={{ width: "340px", height: "204.97px", cursor: "pointer" }}
        className={styles.eventImage}
      />
      <div className={styles.eventInfo}>
        <h3 className={styles.eventTitle}>{title}</h3>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/06330525eb2816a625fd05da8911e37d85a9e2bf2cdf7d384a4885dec9c65e9a?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.infoIcon} />
      </div>
      <div className={styles.deviceInfo}>
        <span className={styles.deviceId}>{deviceId}</span>
        <span className={styles.copyIcon} onClick={onCopy} style={{ cursor: 'pointer' }}>
          {hasCopied ? <FiCheck /> : <FiCopy />} {/* Show check or copy icon */}
        </span>
        <span className={styles.deviceId}> - {name}</span>
      </div>
      <p className={styles.eventLength}>Time: {new Date(TimeStart * 1000).toLocaleString('en-US', { timeZone: 'GMT' })}</p>
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/5d62710cce8a4ec549ff248e0f791cb7705afd2da880c42d9f39b0746ef1f121?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="Play" className={styles.playButton} />
      <span className={styles.edgeSource}>{source} Event</span>
    </article>
  );
};

export default EventCard;
