import React from "react";
import styles from "./Settings.module.css";
import LocalSetup from "./Network-Setting/LocalSetup";
import RTMPSetup from "./Network-Setting/RTMPSetup";
import FTPSetup from "./Network-Setting/FTPSetup";

import SimCardNetworkSetup from "./Network-Setting/SimCardNetworkSetup";
function Network({ selectedMedia, deviceId, onSave }) {
  return (
    <div className={styles.mediaContent}>
      {selectedMedia === "Local Setup" && <LocalSetup deviceId={deviceId} />}
      {/* {selectedMedia === "RTMP Setup" && <RTMPSetup deviceId={deviceId} />}
      {selectedMedia === "FTP Setting" && <FTPSetup deviceId={deviceId} />} */}

      {selectedMedia === "4G Network Settings" && (
        <SimCardNetworkSetup deviceId={deviceId} />
      )}
    </div>
  );
}

export default Network;
