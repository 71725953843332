import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  useColorModeValue, // For theme support
} from '@chakra-ui/react';
import MotionDetection from './MotionDetection';
import HumanDetection from './HumanDetection';
import FaceDetection from './FaceDetection';
import AreaDetection from './AreaDetection';
import LineCrossingDetection from './LineCrossingDetection';
import CustomerTrafficStats from './CustomerTrafficStats';
import UnattendBaggageDetection from './UnattendBaggageDetection';
import MissingObjectDetection from '../Ai-Settings/MissingObjectDetection';

function MobileViewAIsettings({ deviceId }) {
  const bgColor = useColorModeValue('gray.50', 'gray.800'); // Dark mode support

  return (
    <Box w="100%" maxW="100%" p={0} m={0}>
      <Accordion allowMultiple w="100%">
        {/* Motion Detection */}
        <AccordionItem border="none" _notLast={{ mb: 4 }} w="100%">
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton
                  _expanded={{ bg: 'purple.100', color: 'purple.600' }}
                  borderRadius="md"
                  w="100%"
                  px={4}
                  py={3} // Increased padding for better touch targets
                  _hover={{ bg: 'purple.50', cursor: 'pointer' }} // Pointer cursor on hover
                  transition="background 0.2s ease"
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                    Motion Detection
                  </Box>
                  <AccordionIcon
                    transform={isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
                    transition="transform 0.2s ease"
                  />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg={bgColor} borderRadius="md">
                <MotionDetection deviceId={deviceId} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        {/* Human Detection */}
        <AccordionItem border="none" _notLast={{ mb: 4 }} w="100%">
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton
                  _expanded={{ bg: 'purple.100', color: 'purple.600' }}
                  borderRadius="md"
                  w="100%"
                  px={4}
                  py={3}
                  _hover={{ bg: 'purple.50', cursor: 'pointer' }}
                  transition="background 0.2s ease"
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                    Human Detection
                  </Box>
                  <AccordionIcon
                    transform={isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
                    transition="transform 0.2s ease"
                  />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg={bgColor} borderRadius="md">
                <HumanDetection deviceId={deviceId} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        {/* Face Detection */}
        <AccordionItem border="none" _notLast={{ mb: 4 }} w="100%">
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton
                  _expanded={{ bg: 'purple.100', color: 'purple.600' }}
                  borderRadius="md"
                  w="100%"
                  px={4}
                  py={3}
                  _hover={{ bg: 'purple.50', cursor: 'pointer' }}
                  transition="background 0.2s ease"
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                    Face Detection
                  </Box>
                  <AccordionIcon
                    transform={isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
                    transition="transform 0.2s ease"
                  />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg={bgColor} borderRadius="md">
                <FaceDetection deviceId={deviceId} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        {/* Area Detection */}
        <AccordionItem border="none" _notLast={{ mb: 4 }} w="100%">
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton
                  _expanded={{ bg: 'purple.100', color: 'purple.600' }}
                  borderRadius="md"
                  w="100%"
                  px={4}
                  py={3}
                  _hover={{ bg: 'purple.50', cursor: 'pointer' }}
                  transition="background 0.2s ease"
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                    Area Detection
                  </Box>
                  <AccordionIcon
                    transform={isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
                    transition="transform 0.2s ease"
                  />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg={bgColor} borderRadius="md">
                <AreaDetection deviceId={deviceId} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        {/* Line Crossing Detection */}
        <AccordionItem border="none" _notLast={{ mb: 4 }} w="100%">
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton
                  _expanded={{ bg: 'purple.100', color: 'purple.600' }}
                  borderRadius="md"
                  w="100%"
                  px={4}
                  py={3}
                  _hover={{ bg: 'purple.50', cursor: 'pointer' }}
                  transition="background 0.2s ease"
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                    Line Crossing Detection
                  </Box>
                  <AccordionIcon
                    transform={isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
                    transition="transform 0.2s ease"
                  />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg={bgColor} borderRadius="md">
                <LineCrossingDetection deviceId={deviceId} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        {/* Customer Traffic Stats */}
        <AccordionItem border="none" _notLast={{ mb: 4 }} w="100%">
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton
                  _expanded={{ bg: 'purple.100', color: 'purple.600' }}
                  borderRadius="md"
                  w="100%"
                  px={4}
                  py={3}
                  _hover={{ bg: 'purple.50', cursor: 'pointer' }}
                  transition="background 0.2s ease"
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                    Customer Traffic Stats
                  </Box>
                  <AccordionIcon
                    transform={isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
                    transition="transform 0.2s ease"
                  />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg={bgColor} borderRadius="md">
                <CustomerTrafficStats deviceId={deviceId} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        {/* Unattended Baggage Detection */}
        <AccordionItem border="none" _notLast={{ mb: 4 }} w="100%">
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton
                  _expanded={{ bg: 'purple.100', color: 'purple.600' }}
                  borderRadius="md"
                  w="100%"
                  px={4}
                  py={3}
                  _hover={{ bg: 'purple.50', cursor: 'pointer' }}
                  transition="background 0.2s ease"
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                    Unattended Baggage Detection
                  </Box>
                  <AccordionIcon
                    transform={isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
                    transition="transform 0.2s ease"
                  />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg={bgColor} borderRadius="md">
                <UnattendBaggageDetection deviceId={deviceId} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        {/* Missing Object Detection */}
        <AccordionItem border="none" _notLast={{ mb: 4 }} w="100%">
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton
                  _expanded={{ bg: 'purple.100', color: 'purple.600' }}
                  borderRadius="md"
                  w="100%"
                  px={4}
                  py={3}
                  _hover={{ bg: 'purple.50', cursor: 'pointer' }}
                  transition="background 0.2s ease"
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                    Missing Object Detection
                  </Box>
                  <AccordionIcon
                    transform={isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
                    transition="transform 0.2s ease"
                  />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg={bgColor} borderRadius="md">
                <MissingObjectDetection deviceId={deviceId} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
}

export default MobileViewAIsettings;
