import React, { useEffect, useState } from "react";
import styles from "../Settings.module.css";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";

function MotionDetection({ deviceId }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [error, setError] = useState(null);

  const [sensitivityLevel, setSensitivityLevel] = useState("");
  const [isAlarmSoundEnabled, setIsAlarmSoundEnabled] = useState(false);
  const [isWhiteLightEnabled, setIsWhiteLightEnabled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/getmotion`,
          {
            params: {
              deviceId: deviceId,
            },
          }
        );
        setIsEnabled(response.data.enabled);
        setSensitivityLevel(response.data.detectionGrid.sensitivityLevel);
      } catch (error) {
        setError(error);
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/motion-alarm`,
          {
            params: {
              deviceId: deviceId,
            },
          }
        );

        setIsAlarmSoundEnabled(response.data.AlarmSound);
        setIsWhiteLightEnabled(response.data.AlarmWhiteLight);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, []);

  const updateMotion = async () => {
    const parsedSensitivity = parseInt(sensitivityLevel, 10);

    // Validate sensitivity level
    if (
      isNaN(parsedSensitivity) ||
      parsedSensitivity < 1 ||
      parsedSensitivity > 100
    ) {
      setError(new Error("Sensitivity level must be between 1 and 100."));
      return;
    }

    const settings = {
      enabled: isEnabled,
      sensitivityLevel: parsedSensitivity,
    };

    try {
      const responseSettings = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/p2p/motionDetection-settings`,
        settings,
        {
          params: {
            deviceId: deviceId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Motion Detection Settings Response:", responseSettings.data);

      const alarm = {
        AlarmSound: isAlarmSoundEnabled,
        AlarmWhiteLight: isWhiteLightEnabled,
      };

      const responseAlarm = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/p2p/set-motion-alarm`,
        alarm,
        {
          params: {
            deviceId: deviceId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Motion Alarm Settings Response:", responseAlarm.data);
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      setError(error);
    }
  };

  return (
    <Box className={styles.notify}  marginLeft={'0px'}       mt={{ base: "5", md: 0 }}>
      <Text
        color="#7F56D9"
        fontSize={{ base: "20px", md: "24px" }}
        fontWeight="bold"
        mb={4}
        display={{ base: "none", md: "block" }}
      >
        Motion Detection
      </Text>

      <FormControl display="flex" alignItems="center" mb={4}>
        <FormLabel
          htmlFor="enabled"
          fontWeight="bold"
          width={{ base: "120px", md: "150px" }}
        >
          Enabled
        </FormLabel>
        <Checkbox
          isChecked={isEnabled}
          id="enabled"
          onChange={(e) => setIsEnabled(e.target.checked)}
        />
      </FormControl>

      <FormControl display="flex" alignItems="center" mb={4}>
        <FormLabel
          htmlFor="sensitivity"
          fontWeight="bold"
          width={{ base: "120px", md: "150px" }}
        >
          Sensitivity Level
        </FormLabel>
        <Input
          type="text"
          id="sensitivity"
          value={sensitivityLevel}
          onChange={(e) => setSensitivityLevel(e.target.value)}
          width={{ base: "100px", md: "150px", lg: "200px" }}
        />
        <Text ml={2} fontSize={{ base: "12px", md: "14px" }}>
          (0-100)
        </Text>
      </FormControl>

      <FormControl display="flex" alignItems="center" mb={4}>
        <FormLabel
          htmlFor="alarmSound"
          fontWeight="bold"
          width={{ base: "120px", md: "150px" }}
        >
          Enable Alarm Sound
        </FormLabel>
        <Checkbox
          isChecked={isAlarmSoundEnabled}
          id="alarmSound"
          onChange={(e) => setIsAlarmSoundEnabled(e.target.checked)}
        />
      </FormControl>

      <FormControl display="flex" alignItems="center" mb={4}>
        <FormLabel
          htmlFor="whiteLight"
          fontWeight="bold"
          width={{ base: "120px", md: "150px" }}
        >
          Enable White Light
        </FormLabel>
        <Checkbox
          isChecked={isWhiteLightEnabled}
          id="whiteLight"
          onChange={(e) => setIsWhiteLightEnabled(e.target.checked)}
        />
      </FormControl>

      <Button
        onClick={updateMotion}
        bgColor="#7F56D9"
        color="white"
       
        borderRadius="4px"
        fontWeight="bold"
        _hover={{ bgColor: "#6E44CC" }}
        display={{ base: "none", md: "block" }}
        w={'fit-content'}
      >
        Save
      </Button>
    </Box>
  );
}

export default MotionDetection;