import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import styles from '../../assets/styles/Dashboard.module.css';
import { CiCircleQuestion } from "react-icons/ci";
import { logout } from '../../services/auth';
import AddAIDeviceModal from '../AddAIDeviceModal/AddAIDeviceModal';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAIDeviceModalOpen, setIsAIDeviceModalOpen] = useState(false);
  const [pageType, setPageType] = useState('');
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    // Update pageType based on the current location pathname
    if (location.pathname.includes('ai-dashboard')) {
      setPageType('ai-dashboard');
    } else if (location.pathname.includes('dashboard')) {
      setPageType('dashboard');
    }
  }, [location.pathname]);  // Dependency array includes location.pathname to re-run effect on pathname change

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isDropdownOpen && !event.target.closest(`.${styles.dropdownMenu}`) && !event.target.closest(`.${styles.iconButton}`)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);
  
  const handleAddDevice = () => {
    if (pageType === 'ai-dashboard') {
      setIsAIDeviceModalOpen(true);
    } else if (pageType === 'dashboard') {
      setIsModalOpen(true);
    }
  };

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);


  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleOpenFAQ = () => {
    const faqUrl = 'https://ambicam.com/faq';
    window.open(faqUrl, '_blank');
  };

  const handleOpenPrivacyPolicy = () => {
    const faqUrl = 'https://home.arcisai.io/privacypolicy';
    window.open(faqUrl, '_blank');
  };

  const handleOpenTermsAndConditions = () => {
    const faqUrl = 'https://home.arcisai.io/termsandconditions';
    window.open(faqUrl, '_blank');
  };

  const handleSaveClick = () => {
    console.log("Device Added");
  }

  const handleOpenRefundAndCancellation = () => {
    const faqUrl = 'https://home.arcisai.io/refundandcancellation';
    window.open(faqUrl, '_blank');
  };

  // const handleLogout = async () => {
  //   try {
  //     const logoutResult = await logout();

  //     if (logoutResult.success) {
  //       localStorage.removeItem('isLoggedIn');
  //       localStorage.removeItem('token');
  //       navigate('/');
  //     } else {
  //       console.error('Logout error:', logoutResult.message);
  //     }
  //   } catch (error) {
  //     console.error('Logout error:', error);
  //   }
  // };
  const handleLogout = async () => {
    // Perform logout logic here
    try {
      // Call the logout API
      const logoutResult = await logout();

      if (logoutResult.success) {
        // Remove any items from local storage or perform any other necessary cleanup
        localStorage.clear();
        // // console.log('logout',logoutResult)-
        // Redirect to the homepage after logout
        navigate('/');
      } else {
        // Handle logout error
        // You can display a message or take appropriate action
        console.error('Logout error:', logoutResult.message);
      }
    } catch (error) {
      // Handle unexpected errors, e.g., network issues
      console.error('Logout error:', error);
    }

  };

  return (
    <header className={styles.header}>
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/9cadae3544d2e6a3ad6aafa7ad59b08c3216909ee850a29208e6a388b548619a?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="Company Logo" className={styles.logo} />
      <div className={styles.headerActions}>
        {/* <button aria-label="Notification" className={styles.iconButton}>
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/c70e5bf17d782b8e6692d344b3fa9e7b70599685b3f72659b170d4e932e3026c?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.icon} />
        </button>
        <button aria-label="Messages" className={styles.iconButton}>
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/81f0a9dc61b9c74ea77c645e42c02546a3d864000abb057364910a0cb7caf247?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.icon} />
        </button> */}
        <button aria-label="Profile" className={styles.iconButton} onClick={toggleDropdown}>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/c67124c316db5ffb2d81c5c7649279a0a4cc90bea5fb108354f2ffae59e0a484?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1"
            alt="Profile Icon"
            className={styles.icon}
          />
        </button>
        {isDropdownOpen && (
          <div className={styles.dropdownMenu}>
            <ul>
              <li><a onClick={handleOpenFAQ}>FAQ</a></li>
              <li><a onClick={handleOpenPrivacyPolicy}>Privacy Policy</a></li>
              <li><a onClick={handleOpenTermsAndConditions}>Terms and Conditions</a></li>
              <li><a onClick={handleOpenRefundAndCancellation}>Refund and Cancellation</a></li>
              <li><a onClick={handleLogout}>Sign out</a></li>
            </ul>
          </div>
        )}
        <button className={styles.addDeviceButton} onClick={handleAddDevice}>+ device</button>

        {isAIDeviceModalOpen && (
          <AddAIDeviceModal isOpen={isAIDeviceModalOpen} onClose={() => setIsAIDeviceModalOpen(false)} onSave={handleSaveClick} />
        )}

        {isModalOpen && (
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <h1 style={{ color: "black" }}>Add New Device</h1>
              <p style={{ color: "black", fontWeight: "lighter", marginTop: "-10px" }}>Give the description below to add your new camera.
                <form className={styles.form}>
                  <div className={styles.formGroup}>
                    <label htmlFor="deviceDescription" style={{ color: "black", fontWeight: "lighter" }}>Device Name:</label>
                    <input
                      id="deviceDescription"
                      type="text"
                      className={styles.input}
                    />
                    <CiCircleQuestion className={styles.circle} />
                    <span className={styles.helperText}>Find this ID in your email</span>
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="deviceDescription" style={{ color: "black", fontWeight: "lighter" }}>Device ID:</label>
                    <input
                      id="deviceDescription"
                      type="text"
                      className={styles.input}
                    />
                    <CiCircleQuestion className={styles.circle} />
                    <span className={styles.helperText}>Find this ID in your email</span>
                  </div>

                  <div className={styles.buttonGroup}>
                    <button type="submit" className={styles.submitButton} onClick={handleSaveClick}>Save Device</button>
                    <button type="button" className={styles.cancelButton} onClick={() => setIsModalOpen(false)}>Cancel</button>
                  </div>
                </form>
              </p>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
