import axios from "axios";

// const baseURL = 'https://seahorse-app-2-3o2pf.ondigitalocean.app/election';
const baseURL = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL: baseURL
});

// video settings requests

export const getVideoSettings = async (deviceId) => {
  try {
    // Make a GET request to the backend API with the user query
    const response = await instance.get('/p2p/video-settings', {
      params: {
        deviceId: deviceId,
      },
    })
    return response.data;
  } catch (error) {
    // Handle error if API call fails
    console.error('Error fetching response:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};

export const postVideoSettings = async (deviceId, updatedSettings) => {
  try {
    // Make a POST request to the backend API with the user query
    const response = await instance.post('/p2p/postVideo-settings',
      updatedSettings, {
      params: {
        deviceId: deviceId,
      },
    });
    return response;
  } catch (error) {
    // Handle error if API call fails
    console.error('Error fetching response:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};

// ir setup requests

export const getIrSetup = async (deviceId) => {
  try {
    // Make a GET request to the backend API with the user query
    const response = await instance.get('/p2p/ir-setup', {
      params: {
        deviceId: deviceId,
      },
    })
    return response.data;
  } catch (error) {
    // Handle error if API call fails
    console.error('Error fetching response:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};

export const postIrSetup = async (deviceId, imageConfig) => {
  try {
    // Make a POST request to the backend API with the user query
    const response = await instance.post('/p2p/ir-setup',
      imageConfig, {
      params: {
        deviceId: deviceId,
      },
    });
    return response;
  } catch (error) {
    // Handle error if API call fails
    console.error('Error fetching response:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};

/////////// VIDEO QUALITY /////////////

export const getQuality = async (deviceId) => {
  try {
    // Make a GET request to the backend API with the user query
    const response = await instance.post('/p2p/getQuality',
        {
          deviceid: deviceId,
        })
    return response;
  } catch (error) {
    // Handle error if API call fails
    console.error('Error fetching response:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};

export const updateQuality = async (deviceId, quality) => {
  try {
    // Make a POST request to the backend API with the user query
    const response = await instance.post('/p2p/updateQuality',
      {
        deviceid: deviceId,
        quality: quality, // Use the parameter value directly
      });
    return response;
  } catch (error) {
    // Handle error if API call fails
    console.error('Error fetching response:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};

/////////// Reboot ///////////

export const rebootCam = async (deviceId, quality) => {
  try {
    // Make a POST request to the backend API with the user query
    const response = await instance.put('/p2p/reboot',
      {
        deviceid: deviceId,
        quality: quality, // Use the parameter value directly
      });
    return response;
  } catch (error) {
    // Handle error if API call fails
    console.error('Error fetching response:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};