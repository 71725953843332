import React, { useState, useRef, useEffect } from "react";
import MODCanvas from "../../MODCanvas";
import axios from "axios";

import styles from "../Settings.module.css";
import {
  Box,
  Checkbox,
  Button,
  Input,
  Select,
  Text,
  Stack,
  Flex,
  FormLabel,
} from "@chakra-ui/react";

function MissingObjectDetection({ deviceId }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [alarmSound, setAlarmSound] = useState();
  const [whiteLightAlarm, setWhiteLightAlarm] = useState();
  const [appMessagePush, setAppMessagePush] = useState();
  const [rtmpPush, setRtmpPush] = useState();
  const [ftpPush, setFtpPush] = useState();
  const [direction, setDirection] = useState("");
  const [RepeatAlarmTime, setRepeatAlarmTime] = useState();
  const [Sensitivity, setSensitivity] = useState();
  const [MinDuration, setMinDuration] = useState();
  const [error, setError] = useState(null);
  const [Action, setAction] = useState("");
  const [DetectRegion, setDetectRegion] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/getMissingObjectDetection`,
          {
            params: {
              deviceId: deviceId,
            },
          }
        );

        setIsEnabled(response.data.Enabled);
        setRtmpPush(response.data.AlarmOut.RtmpPush.Enabled);
        setFtpPush(response.data.AlarmOut.FtpPush.Enabled);
        setAlarmSound(response.data.AlarmOut.AudioAlert.Enabled);
        setWhiteLightAlarm(response.data.AlarmOut.LightAlert.Enabled);
        setAppMessagePush(response.data.AlarmOut.AppPush.Enabled);
        setMinDuration(response.data.Duration);
        setSensitivity(response.data.Sensitivity);
        setDetectRegion(response.data.DetectAera);
        console.log(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]);

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/p2p/setMissingObjectDetection`,
        {
          Enabled: isEnabled,
          DetectRegion: DetectRegion,
          MinDuration: MinDuration,
          Sensitivity: Sensitivity,
          AlarmOut: {
            AudioAlert: {
              Enabled: alarmSound,
            },
            LightAlert: {
              Enabled: whiteLightAlarm,
            },
            AppPush: {
              Enabled: appMessagePush,
            },
            RtmpPush: {
              Enabled: rtmpPush,
            },
            FtpPush: {
              Enabled: ftpPush,
            },
            Email: {
              Enabled: false,
            },
            gat1400: {
              Enabled: false,
            },
          },
        },
        {
          params: { deviceId: deviceId },
        }
      );

      console.log("Settings saved", response.data);
    } catch (error) {
      console.error("Error saving settings", error);
    }
  };

  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const openCanvasModal = () => {
    setIsCanvasModalOpen(true);
  };

  const closeCanvasModal = () => {
    setIsCanvasModalOpen(false);
  };

  const [isCanvasModalOpen, setIsCanvasModalOpen] = useState(false);
  const handleCanvasData = (region, direction) => {
    setDetectRegion(region);
    setDirection(direction);
  };

  return (
    <Box className={styles.notify}  marginLeft={'0px'} mt={{base:'5',md:0}}
    >
      <Text
        color="#7F56D9"
        fontSize={{ base: "20px", md: "24px" }}
        fontWeight="bold"
        mb={4}
        display={{ base: "none", md: "block" }}
      >
        Missing Object Detection
      </Text>

      <Stack spacing={3}>
        <Flex alignItems="center">
          <FormLabel htmlFor="isEnabled" flex="0 0 150px" fontWeight="bold">
            Enabled
          </FormLabel>
          <Checkbox
            isChecked={isEnabled}
            id="isEnabled"
            onChange={(e) => setIsEnabled(e.target.checked)}
            borderColor="black"
          />
        </Flex>

        <Flex alignItems="center">
          <FormLabel htmlFor="alarmSound" flex="0 0 150px" fontWeight="bold">
            Enable Alarm Sound
          </FormLabel>
          <Checkbox
            isChecked={alarmSound}
            id="alarmSound"
            onChange={(e) => setAlarmSound(e.target.checked)}
            borderColor="black"
          />
        </Flex>

        <Flex alignItems="center">
          <FormLabel
            htmlFor="whiteLightAlarm"
            flex="0 0 150px"
            fontWeight="bold"
          >
            Enable White Light Alarm Sound
          </FormLabel>
          <Checkbox
            isChecked={whiteLightAlarm}
            id="whiteLightAlarm"
            onChange={(e) => setWhiteLightAlarm(e.target.checked)}
            borderColor="black"
          />
        </Flex>

        <Flex alignItems="center">
          <FormLabel
            htmlFor="appMessagePush"
            flex="0 0 150px"
            fontWeight="bold"
          >
            Enable App Message Push
          </FormLabel>
          <Checkbox
            isChecked={appMessagePush}
            id="appMessagePush"
            onChange={(e) => setAppMessagePush(e.target.checked)}
            borderColor="black"
          />
        </Flex>

        <Flex alignItems="center">
          <FormLabel htmlFor="rtmpPush" flex="0 0 150px" fontWeight="bold">
            Enable RTMP Push
          </FormLabel>
          <Checkbox
            isChecked={rtmpPush}
            id="rtmpPush"
            onChange={(e) => setRtmpPush(e.target.checked)}
            borderColor="black"
          />
        </Flex>

        <Flex alignItems="center">
          <FormLabel htmlFor="ftpPush" flex="0 0 150px" fontWeight="bold">
            Enable FTP Push
          </FormLabel>
          <Checkbox
            isChecked={ftpPush}
            id="ftpPush"
            onChange={(e) => setFtpPush(e.target.checked)}
            borderColor="black"
          />
        </Flex>

        <Flex alignItems="center">
          <FormLabel htmlFor="minDuration" flex="0 0 150px" fontWeight="bold">
            Minimum Duration(s)
          </FormLabel>
          <Input
            type="text"
            placeholder="MinDuration"
            width="150px"
            borderRadius="4px"
          />
        </Flex>

        <Flex alignItems="center">
          <FormLabel
            htmlFor="sensitivity"
            flex="0 0 150px"
            marginRight="16px"
            fontWeight="bold"
          >
            Sensitivity Level
          </FormLabel>
          <Select
            width="150px"
            value={Sensitivity}
            onChange={(e) => setSensitivity(Number(e.target.value))}
            borderRadius="4px"
            backgroundColor="#fff"
          >
            {Array.from({ length: 11 }, (_, i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </Select>
        </Flex>

        <Button
          onClick={openCanvasModal}
          backgroundColor="#88ffcc"
          color="black"
          borderRadius="4px"
          w="fit-content"
        >
          Open Canvas
        </Button>

        <Button
          onClick={handleSave}
          backgroundColor="#7F56D9"
          color="white"
          borderRadius="4px"
          w={"fit-content"}
          display={{ base: "none", md: "block" }}
        >
          Save
        </Button>
      </Stack>

      <MODCanvas
        isOpen={isCanvasModalOpen}
        onClose={closeCanvasModal}
        onCanvasData={handleCanvasData}
        existingCoordinates={DetectRegion}
        deviceId={deviceId}
      />
    </Box>
  );
}

export default MissingObjectDetection;