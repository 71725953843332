import React, { useState, useRef, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  Button,
  Input
} from '@chakra-ui/react';
import CanvasModal from './LineCrossCanvas';
import axios from 'axios';
import LineCrossCanvas from './LineCrossCanvas';
import CustomerCanvas from './CustomerCanvas';

const CustomerTrafficStats = ({deviceId}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [nLastHour, setnLastHour] = useState(0);
  const [spOSD, setspOSD] = useState(false);
  const [direction, setDirection] = useState('A->B');
  const [error, setError] = useState(null);
  const [detectLine, setDetectLine] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/p2p/getcts`,{
          params: {
            deviceId: deviceId
          }});
         
          setIsEnabled(response.data.Enabled)
          setDirection(response.data.Direction)
          setDetectLine(response.data.DetectLine);
          setspOSD(response.data.spOSD);
   
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]); 

  const handleSave = async () => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/p2p/setcts`, {
        Enabled: isEnabled,
        DetectLine: detectLine,
        DetectObj: 'Human',
        Direction: direction,
        nLastHour:nLastHour,
        spOSD:spOSD,
      }, {
        params: { deviceId: deviceId }
      });

      console.log('Settings saved', response.data);
    } catch (error) {
      console.error('Error saving settings', error);
    }
  };


  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const openCanvasModal = () => {
    setIsCanvasModalOpen(true);
  };

  const closeCanvasModal = () => {
    setIsCanvasModalOpen(false);
  };
  
  const [isCanvasModalOpen, setIsCanvasModalOpen] = useState(false);
  const handleCanvasData = (line, direction) => {
    setDetectLine(line);
    setDirection(direction);
  };
  return (
    <Flex direction="column" padding="10px" gap={4}>
            <Box>
              <Text fontWeight="bold" fontSize="lg" color="blue.500">
                Customer Traffic Statistics
              </Text>
            </Box>
            <Flex>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0" mr={4}>
                  Enable
                </FormLabel>
                <Checkbox isChecked={isEnabled} onChange={(e) => setIsEnabled(e.target.checked)} />
              </FormControl>
            </Flex>
            <Flex>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0" mr={4}>
                  Real-Time Display of Customer Traffic
                </FormLabel>
                <Checkbox isChecked={spOSD} onChange={(e) => setspOSD(e.target.checked)} />
              </FormControl>
            </Flex>
            {/* <Flex direction="row" align="center" gap={4}>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0" mr={4}>
                  Reference line angle setting
                </FormLabel>
                <Input placeholder="Enter angle" width="auto" />
              </FormControl>
            </Flex> */}
            {/* <Flex direction="row" align="center" gap={4}>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0" mr={4}>
                  Reference line width setting
                </FormLabel>
                <Button size="sm" colorScheme="blue" mr={2}>
                  Enlarge
                </Button>
                <Button size="sm" colorScheme="blue">
                  Reduce
                </Button>
              </FormControl>
            </Flex> */}
            {/* <Flex direction="row" align="center" gap={4}>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0">Direction</FormLabel>
                <Select width="auto" placeholder="Direction">
                  <option value="AtoB">AtoB</option>
                  <option value="BtoA">BtoA</option>
                </Select>
              </FormControl>
            </Flex> */}
            <Box mt={4}>
            <Button colorScheme="teal" onClick={openCanvasModal}>
              Open Canvas
            </Button>
          </Box>
              <CustomerCanvas isOpen={isCanvasModalOpen}  onClose={closeCanvasModal} onCanvasData={handleCanvasData} 
              existingCoordinates={detectLine}
              existingDirection={direction}
              deviceId={deviceId}
              />
            <Box mt={4}>
              <Button colorScheme="blue" onClick={handleSave}>Save</Button>
            </Box>
          </Flex>
  );
};

export default CustomerTrafficStats;
