import React from 'react';
import styles from '../Notification/Calender.module.css'; // Adjust the import path as needed

// Utility function to generate week dates
const getWeekDates = (endDate) => {
  const dates = [];
  const dayMilliseconds = 24 * 60 * 60 * 1000;
  const options = { day: '2-digit', month: 'short' }; // Format for date
  const dayOptions = { weekday: 'long' }; // Format for day of the week

  for (let i = 6; i >= 0; i--) { // Start from 6 to get the last 6 days and today
    const date = new Date(endDate.getTime() - i * dayMilliseconds);
    dates.push({
      date: date.toLocaleDateString('en-US', options),
      day: date.toLocaleDateString('en-US', dayOptions),
      fullDate: date.toLocaleDateString('en-CA'), // Use en-CA format (YYYY-MM-DD) for comparison
    });
  }
  return dates;
};


const Calendar = ({ selectedDate, onDateSelect }) => {
  const [startDate, setStartDate] = React.useState(new Date()); // Default to the current week
  const today = new Date(); // Get today's date
  const currentWeekDates = getWeekDates(startDate);

  const goToPreviousWeek = () => {
    const newStartDate = new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000);
    setStartDate(newStartDate);
  };

  const goToNextWeek = () => {
    const nextWeekStartDate = new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    if (nextWeekStartDate <= today) { // Prevent advancing beyond the current week
      setStartDate(nextWeekStartDate);
    }
  };

  const handleDateClick = (date) => {
    onDateSelect(date); // Call the function passed from the parent component
  };

  return (
    <div className={styles.calendarWrapper}>
      <div className={styles.calendarControls}>
        <select id="deviceSelect" className={styles.select}>
          <option>All Device</option>
        </select>
        <button className={styles.prevWeek} onClick={goToPreviousWeek}>Previous Week</button>
        <button className={styles.nextWeek} onClick={goToNextWeek}>Next Week</button>
      </div>
      <div className={styles.dateList}>
        {currentWeekDates.map((date, index) => (
          <div
            key={index}
            className={`${styles.dateItem} ${date.fullDate === selectedDate ? styles.selected : ''}`}
            onClick={() => handleDateClick(date.fullDate)}
          >
            <div className={styles.date}>{date.date}</div>
            <div className={styles.day}>{date.day}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
