import React from 'react';
import styles from './Dashboard.module.css';

function DeviceCard({ status1, name, serial, status, lastSnapshot, image ,onClick}) {
  return (
    <article className={styles.deviceCard} onClick={onClick}>
      <img src={image} alt={`${name} snapshot`} className={styles.deviceImage} />
      <div className={styles.deviceInfo}>
        <h3 className={styles.deviceName}>{name}</h3>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/0402d72c28a2a4f0955de54a911d20e8e980fca363eb868427e7d1b8abe86052?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.deviceIcon} />
      </div>
      {/* <div className={styles.deviceSerial}>
        <span>{serial}</span>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/96bff4fd3c64131e284f884988c8583109aa215f618cff8b391d9905beea4f4e?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.serialIcon} />
      </div> */}
      {/* <p className={styles.deviceSnapshot}>Snapshot: {lastSnapshot}</p> */}
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/6edb3eab5d03ae495340f14fc6256d578de6449742ce6b2f163d090e5c19cb4e?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.deviceStatusIcon} />
      <div className={`${styles.deviceStatus} ${styles[status1]}`}>{status1}</div>
    </article>
  );
}

export default DeviceCard;