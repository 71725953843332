import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "../Settings.module.css";

import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Select,
  Button,
  Flex,
  useToast,
  VStack,
  HStack,
  Input,
} from "@chakra-ui/react";

function Video_Setup({ deviceId, onSave }) {
  const [data, setData] = useState({
    microphoneType: "",
    profile: "",
    encodeType: "",
    stream: "",
    resolution: "",
    bitRateControlType: "",
    // channelName: "",
    constantBitRate: "",
    frameRate: "",
  });
  const [error, setError] = useState(null);
  const [quality, setQuality] = useState(""); // Default empty or a preset value
  const [loading, setLoading] = useState(false); // Loading state for asynchronous submission
  const toast = useToast(); // For displaying notifications
  const handleQualityChange = async (e) => {
    const selectedQuality = e.target.value;
    setQuality(selectedQuality);

    // Automatically trigger the submission process with the selected quality
    await handleSubmit(selectedQuality);
  };
  // Fetch the current video settings when the component mounts

  const fetchData = async () => {
    try {
      // const response = await axios.get(
      //   `${process.env.REACT_APP_BASE_URL}/p2p/get-videoSettings`,
      //   {
      //     params: { deviceid: deviceId },
      //   }
      // );
      // console.log(response.data);
      // setData(response.data);
      const qualityResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/p2p/getQuality`,
        {
          deviceid: deviceId,
        }
      );

      // Assuming the quality is inside the response data
      if (qualityResponse.data && qualityResponse.data.quality) {
        setQuality(qualityResponse.data.quality); // Set quality from the response
      } else {
        setQuality("high"); // Default to 'high' if no quality found
      }
    } catch (error) {
      setError(error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [deviceId]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to save the settings
  const handleMediaContent = () => {
    const updatedData = {
      deviceId: deviceId,
      codecType: data.profile,
      h264Profile: "high", // This value is static, adjust as necessary
      resolution: data.resolution,
      freeResolution: false,
      bitRateControlType: data.bitRateControlType,
      constantBitRate: parseInt(data.constantBitRate, 10), // Ensure BPS is a number
      frameRate: parseInt(data.frameRate, 10), // Ensure FPS is a number
      // channelName: data.channelName,
    };

    const config = {
      headers: {
        Authorization: "Basic " + btoa("admin:"), // Assuming 'admin' is the username with no password
        "Content-Type": "application/json",
      },
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/p2p/video-setup`,
        updatedData,
        config
      )
      .then((response) => {
        // console.log("Response:", response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Pass the save function to the parent component
  useEffect(() => {
    onSave(handleMediaContent);
  }, [onSave, data]);

  const handleSubmit = async (selectedQuality) => {
    setLoading(true); // Start loading spinner or state

    try {
      // Send request to backend API to update quality
      await axios.put(`${process.env.REACT_APP_BASE_URL}/p2p/updateQuality`, {
        quality: selectedQuality, // Use the parameter value directly
        deviceid: deviceId, // Pass the selected device ID
      });

      // Display success message with selected quality
      toast({
        title: "Success!",
        description: `Video quality changed to ${selectedQuality}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      // Display error message if submission fails
      toast({
        title: "Error",
        description: "Failed to change video quality",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false); // Stop loading spinner or state
    }
  };
  return (
    <Box
      className={styles.notify}
      marginLeft={"0px"}
      mt={{ base: "-10", md: 0 }}
    >
      {/* Heading */}
      <VStack align="start" spacing={4} mb={4} color="#7F56D9">
        <Text fontSize={{ base: "18px", md: "24px" }} fontWeight="bold">
          General Setting
        </Text>
      </VStack>

      {/* Flexbox for Horizontal Alignment */}
      <HStack spacing={{ base: 3, md: 8 }} mb={4} width="full">
        {/* Microphone Type */}
        <FormControl display="flex" alignItems="center">
          <FormLabel
            fontSize={{ base: "14px", md: "18px" }}
            fontWeight="bold"
            minWidth={{ base: "100px", md: "150px" }}
            mb={{ base: 2, md: 0 }}
          >
            Microphone Type
          </FormLabel>
          <Select
            name="microphoneType"
            value={data.microphoneType}
            onChange={handleInputChange}
            size="lg"
            width={{ base: "100%", md: "200px" }}
            fontSize={{ base: "14px", md: "16px" }}
          >
            <option value="Active Pickup">Active Pickup</option>
            <option value="Passive Mic">Passive Mic</option>
          </Select>
        </FormControl>
      </HStack>

      <HStack spacing={{ base: 3, md: 8 }} mb={4} width="full">
        <FormControl display="flex" alignItems="center">
          <FormLabel
            fontSize={{ base: "14px", md: "18px" }}
            fontWeight="bold"
            minWidth={{ base: "100px", md: "150px" }}
            mb={{ base: 2, md: 0 }}
          >
            Quality
          </FormLabel>
          <Select
            value={quality}
            onChange={handleQualityChange}
            size="lg"
            width={{ base: "100%", md: "200px" }}
            disabled={loading}
            fontSize={{ base: "14px", md: "16px" }}
          >
            <option value="low">Low</option>
            <option value="mid">Mid</option>
            <option value="high">High</option>
          </Select>
        </FormControl>
      </HStack>

      {/* Save Button */}
      <Box mt={6} textAlign="center">
        <Button
          colorScheme="purple"
          size="lg"
          onClick={handleMediaContent}
          isLoading={loading}
          display={{ base: "none", md: "block" }} // Display Save button in both views
          fontSize={{ base: "14px", md: "16px" }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}

export default Video_Setup;