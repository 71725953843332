import React, { useState, useRef, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  Button,
  VStack,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb
} from '@chakra-ui/react';

import axios from 'axios';


const ISPSetup = ({deviceId}) => {
    const [isChecked, setIsChecked] = useState(false);
    const handleDropdownChange = async (event) => {
        const selectedValue = event.target.value;
    
        console.log(selectedValue)
      
        const imageConfig = {
          "irCutFilter": {
            "irCutMode": selectedValue
          }
        };
   
        try {
          const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/p2p/ir-setup`, imageConfig, {
            params: {
              deviceId: deviceId
            },
            headers: {
              'Content-Type': 'application/json'
            }
          });
          console.log('Response:', response.data);
        } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
        }
      };
    
      const handleDigitalCheckboxChange = async (event) => {
        const checked = event.target.checked;
      setIsChecked(checked);
  
      const enable = {
        enable: checked
      };
  
      try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/p2p/dwdr`, enable, {
          params: {
            deviceId: deviceId
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic YWRtaW46' // Base64 encoding of "admin:"
          }
        });
        console.log('Response:', response.data);
      } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
      }
  
      };
  
  return (

    <Flex direction="column" padding="10px" align="start" gap={4}>
    <Box>
      <Select placeholder="Software Ircut control" disabled="true" >
        <option value="option1">Option 1</option>
        <option value="option2">Option 2</option>
        <option value="option3">Option 3</option>
      </Select>
    </Box>
    <Box>
      <Select onChange={handleDropdownChange}>
        <option value="irledmode">irledmode</option>
        <option value="lightmode">lightmode</option>
        <option value="smart">smartmode</option>
        <option value="daylight">daylight</option>
        <option value="night">night</option>
      </Select>
    </Box>
    <Box>
      <Checkbox isChecked={isChecked} onChange={handleDigitalCheckboxChange}>Digital width dynamic setting</Checkbox>
    </Box>
  </Flex>

    
);
};

export default ISPSetup;