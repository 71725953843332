import React, { useState, useEffect } from 'react';
import {
  VStack,
  FormControl,
  FormLabel,
  Checkbox,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb
} from '@chakra-ui/react';
import axios from 'axios';

const ImageSetup = ({ deviceId }) => {
  const [contrast, setContrast] = useState(50);
  const [brightness, setBrightness] = useState(50);
  const [saturation, setSaturation] = useState(50);
  const [hue, setHue] = useState(50);
  const [sharpen, setSharpen] = useState(50);
  const [flipChecked, setFlipChecked] = useState(false);
  const [mirrorChecked, setMirrorChecked] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/p2p/video-settings`,{
          params: {
            deviceid: deviceId
          }
        });
        setContrast(response.data.contrastLevel);
        setBrightness(response.data.brightnessLevel);
        setSaturation(response.data.saturationLevel);
        setHue(response.data.hueLevel);
        setSharpen(response.data.sharpnessLevel);
        setFlipChecked(response.data.flipEnabled);
        setMirrorChecked(response.data.mirrorEnabled);
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };
    fetchSettings();
  }, [deviceId]);

  const updateVideoSettings = async (settings) => {
    console.log(deviceId)
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/p2p/postVideo-settings`, settings,{
        params: {
          deviceid: deviceId
        }
      });
      console.log('Settings updated:', response.data);
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  const handleContrastChange = (value) => {
    setContrast(value);
    updateVideoSettings({ 
      contrastLevel: value, 
      brightnessLevel: brightness, 
      saturationLevel: saturation, 
      hueLevel: hue, 
      sharpnessLevel: sharpen, 
      flipEnabled: flipChecked, 
      mirrorEnabled: mirrorChecked 
    });
  };

  const handleBrightnessChange = (value) => {
    setBrightness(value);
    updateVideoSettings({ 
      contrastLevel: contrast, 
      brightnessLevel: value, 
      saturationLevel: saturation, 
      hueLevel: hue, 
      sharpnessLevel: sharpen, 
      flipEnabled: flipChecked, 
      mirrorEnabled: mirrorChecked 
    });
  };

  const handleSaturationChange = (value) => {
    setSaturation(value);
    updateVideoSettings({ 
      contrastLevel: contrast, 
      brightnessLevel: brightness, 
      saturationLevel: value, 
      hueLevel: hue, 
      sharpnessLevel: sharpen, 
      flipEnabled: flipChecked, 
      mirrorEnabled: mirrorChecked 
    });
  };

  const handleHueChange = (value) => {
    setHue(value);
    updateVideoSettings({ 
      contrastLevel: contrast, 
      brightnessLevel: brightness, 
      saturationLevel: saturation, 
      hueLevel: value, 
      sharpnessLevel: sharpen, 
      flipEnabled: flipChecked, 
      mirrorEnabled: mirrorChecked 
    });
  };

  const handleSharpenChange = (value) => {
    setSharpen(value);
    updateVideoSettings({ 
      contrastLevel: contrast, 
      brightnessLevel: brightness, 
      saturationLevel: saturation, 
      hueLevel: hue, 
      sharpnessLevel: value, 
      flipEnabled: flipChecked, 
      mirrorEnabled: mirrorChecked 
    });
  };

  const handleFlipChange = (e) => {
    setFlipChecked(e.target.checked);
    updateVideoSettings({ 
      contrastLevel: contrast, 
      brightnessLevel: brightness, 
      saturationLevel: saturation, 
      hueLevel: hue, 
      sharpnessLevel: sharpen, 
      flipEnabled: e.target.checked, 
      mirrorEnabled: mirrorChecked 
    });
  };

  const handleMirrorChange = (e) => {
    setMirrorChecked(e.target.checked);
    updateVideoSettings({ 
      contrastLevel: contrast, 
      brightnessLevel: brightness, 
      saturationLevel: saturation, 
      hueLevel: hue, 
      sharpnessLevel: sharpen, 
      flipEnabled: flipChecked, 
      mirrorEnabled: e.target.checked 
    });
  };

  return (
    <VStack spacing={4} align="stretch">
      <FormControl>
        <FormLabel>Contrast</FormLabel>
        <Slider value={contrast} min={0} max={100} step={1} onChange={handleContrastChange}>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </FormControl>
      <FormControl>
        <FormLabel>Brightness</FormLabel>
        <Slider value={brightness} min={0} max={100} step={1} onChange={handleBrightnessChange}>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </FormControl>
      <FormControl>
        <FormLabel>Saturation</FormLabel>
        <Slider value={saturation} min={0} max={100} step={1} onChange={handleSaturationChange}>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </FormControl>
      <FormControl>
        <FormLabel>Hue</FormLabel>
        <Slider value={hue} min={0} max={100} step={1} onChange={handleHueChange}>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </FormControl>
      <FormControl>
        <FormLabel>Sharpen</FormLabel>
        <Slider value={sharpen} min={0} max={100} step={1} onChange={handleSharpenChange}>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </FormControl>
      <FormControl>
        <Checkbox isChecked={flipChecked} onChange={handleFlipChange}>
          Flip
        </Checkbox>
      </FormControl>
      <FormControl>
        <Checkbox isChecked={mirrorChecked} onChange={handleMirrorChange}>
          Mirror
        </Checkbox>
      </FormControl>
    </VStack>
  );
};

export default ImageSetup;
