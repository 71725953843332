import styles from './LoginPage.module.css';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { login, sendOtp, verifyOtp } from '../../services/auth'; // You should have sendOtp and verifyOtp functions
import { Box, useToast } from '@chakra-ui/react';

function LoginForm() {
  const [email, setEmail] = useState(''); // Unified field for email or mobile
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [isMobileNumber, setIsMobileNumber] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const showToast = (msg, status) => {
    toast({
      description: msg,
      status: status,
      duration: 3000,
      position: 'bottom-left',
      isClosable: true,
    });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Check if the input is a mobile number
    const mobileRegex = /^[6-9]\d{9}$/;
    setIsMobileNumber(mobileRegex.test(value));
    setIsOtpSent(false); // Reset OTP sent status when input changes
  };

  const handleSendOtp = async () => {
    try {
      setIsLoading(true);
      const sendOtpResult = await sendOtp(email); // Assuming the sendOtp function exists
      if (sendOtpResult.success) {
        showToast('OTP sent successfully', 'success');
        setIsOtpSent(true);
      } else {
        setErrorMessage('Failed to send OTP. Please try again.');
        showToast('Failed to send OTP', 'error');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setErrorMessage('Failed to send OTP. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      setIsLoading(true);
      const verifyResult = await verifyOtp(email, otp); // Assuming the verifyOtp function exists
      if (verifyResult.success) {
        localStorage.setItem('email', verifyResult.user.email);
        localStorage.setItem('userId', verifyResult.user.customerid);
        // Perform any login redirection logic
        navigate('/dashboard');
        showToast('OTP verified successfully. Logging in...', 'success');
      } else {
        setErrorMessage('Invalid OTP. Please try again.');
        showToast('Invalid OTP', 'error');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setErrorMessage('Failed to verify OTP. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission

    if (!email || (!password && !isMobileNumber)) {
      setErrorMessage('Please enter all required fields.');
      return;
    }

    try {
      setErrorMessage('');
      setIsLoading(true); // Show loader during login

      const loginResult = await login(email, password);
      console.log('Login result:', loginResult);
      if (loginResult.success) {
        localStorage.setItem('email', loginResult.user.email);
        localStorage.setItem('userId', loginResult.user.customerid);
        navigate('/dashboard');
        showToast('Logged in Successfully', 'success');
      } else {
        setErrorMessage(loginResult.message);
        showToast(loginResult.message, 'error');
      }
    } catch (error) {
      setErrorMessage('Failed to login. Please try again.');
      console.error('Error:', error);
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  return (
    <form className={styles.loginForm} onSubmit={handleLogin}>
      <div className={styles.inputGroup}>
        <label htmlFor="email" className={styles.label}>
          Email or Mobile
        </label>
        <input
          type="text"
          id="email"
          className={styles.input}
          style={{marginBottom: '10px'}}
          placeholder="Enter your email or mobile number"
          value={email}
          onChange={handleInputChange}
          required
        />
      </div>

      {!isMobileNumber && (
        <div className={styles.inputGroup}>
          <label htmlFor="password" className={styles.label}>
            Password
          </label>
          <input
            type="password"
            id="password"
            className={styles.input}
            placeholder="••••••••"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
      )}

      <Box display={'flex'} justifyContent={'end'} marginBottom={3} color={'#9678E1'}>
        <button onClick={() => navigate('/forgotPassword')}>
          Forgot Password?
        </button>
      </Box>

      {isMobileNumber && !isOtpSent && (
        <button
          type="button"
          className={styles.submitButton}
          onClick={handleSendOtp}
          disabled={isLoading}
        >
          {isLoading ? 'Sending OTP...' : 'Send OTP'}
        </button>
      )}

      {isOtpSent && (
        <>
          <div className={styles.inputGroup}>
            <label htmlFor="otp" className={styles.label}>
              Enter OTP
            </label>
            <input
              type="text"
              id="otp"
              className={styles.input}
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
          </div>
          <button
            type="button"
            className={styles.submitButton}
            onClick={handleVerifyOtp}
            disabled={isLoading}
          >
            {isLoading ? 'Verifying OTP...' : 'Verify OTP'}
          </button>
        </>
      )}

      {!isMobileNumber && (
        <button type="submit" className={styles.submitButton} disabled={isLoading}>
          {isLoading ? 'Logging in...' : 'Sign in'}
        </button>
      )}

      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </form>
  );
}

export default LoginForm;
