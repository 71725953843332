import React, { useState, useRef, useEffect } from "react";

import styles from "../Settings.module.css";
import UAOCanvas from "../../UAOCanvas";
import axios from "axios";
import {
  Box,
  Flex,
  Text,
  Checkbox,
  Input,
  Select,
  Button,
} from "@chakra-ui/react";

function UnattendBaggageDetection({ deviceId }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [alarmSound, setAlarmSound] = useState();
  const [whiteLightAlarm, setWhiteLightAlarm] = useState();
  const [appMessagePush, setAppMessagePush] = useState();
  const [rtmpPush, setRtmpPush] = useState();
  const [ftpPush, setFtpPush] = useState();
  const [direction, setDirection] = useState("");
  const [RepeatAlarmTime, setRepeatAlarmTime] = useState();
  const [Sensitivity, setSensitivity] = useState();
  const [MinDuration, setMinDuration] = useState();
  const [error, setError] = useState(null);
  const [Action, setAction] = useState("");
  const [DetectRegion, setDetectRegion] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/getUnattendedObjDetect`,
          {
            params: {
              deviceId: deviceId,
            },
          }
        );

        setIsEnabled(response.data.Enabled);
        setRtmpPush(response.data.AlarmOut.RtmpPush.Enabled);
        setFtpPush(response.data.AlarmOut.FtpPush.Enabled);
        setAlarmSound(response.data.AlarmOut.AudioAlert.Enabled);
        setWhiteLightAlarm(response.data.AlarmOut.LightAlert.Enabled);
        setAppMessagePush(response.data.AlarmOut.AppPush.Enabled);
        setMinDuration(response.data.Duration);
        setSensitivity(response.data.Sensitivity);
        setDetectRegion(response.data.DetectAera);
        console.log(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]);

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/p2p/setUnattendedObjDetect`,
        {
          Enabled: isEnabled,
          DetectRegion: DetectRegion,
          MinDuration: MinDuration,
          Sensitivity: Sensitivity,
          AlarmOut: {
            AudioAlert: {
              Enabled: alarmSound,
            },
            LightAlert: {
              Enabled: whiteLightAlarm,
            },
            AppPush: {
              Enabled: appMessagePush,
            },
            RtmpPush: {
              Enabled: rtmpPush,
            },
            FtpPush: {
              Enabled: ftpPush,
            },
            Email: {
              Enabled: false,
            },
            gat1400: {
              Enabled: false,
            },
          },
        },
        {
          params: { deviceId: deviceId },
        }
      );

      console.log("Settings saved", response.data);
    } catch (error) {
      console.error("Error saving settings", error);
    }
  };

  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const openCanvasModal = () => {
    setIsCanvasModalOpen(true);
  };

  const closeCanvasModal = () => {
    setIsCanvasModalOpen(false);
  };

  const [isCanvasModalOpen, setIsCanvasModalOpen] = useState(false);
  const handleCanvasData = (region, direction) => {
    setDetectRegion(region);
    setDirection(direction);
  };
  return (
    <Box
    className={styles.notify}  marginLeft={'0px'} mt={{base:'5',md:0}}
    >
      <Text
        color="#7F56D9"
        fontSize={{ base: "20px", md: "24px" }}
        fontWeight="bold"
        mb={4}
        display={{ base: "none", md: "block" }}
      >
        Unattend Baggage Detection
      </Text>

      <Flex mb="7px" alignItems="center">
        <Text flex="0 0 150px" mr="16px" fontWeight="bold">
          Enabled
        </Text>
        <Checkbox
          isChecked={isEnabled}
          id="enabled"
          onChange={(e) => setIsEnabled(e.target.checked)}
        />
      </Flex>

      <Flex mb="7px" alignItems="center">
        <Text flex="0 0 150px" mr="16px" fontWeight="bold">
          Enable Alarm Sound
        </Text>
        <Checkbox
          isChecked={alarmSound}
          id="alarmSound"
          onChange={(e) => setAlarmSound(e.target.checked)}
        />
      </Flex>

      <Flex mb="7px" alignItems="center">
        <Text flex="0 0 150px" mr="16px" fontWeight="bold">
          Enable White Light Alarm Sound
        </Text>
        <Checkbox
          isChecked={whiteLightAlarm}
          id="whiteLightAlarm"
          onChange={(e) => setWhiteLightAlarm(e.target.checked)}
        />
      </Flex>

      <Flex mb="7px" alignItems="center">
        <Text flex="0 0 150px" mr="16px" fontWeight="bold">
          Enable App Message Push
        </Text>
        <Checkbox
          isChecked={appMessagePush}
          id="appMessagePush"
          onChange={(e) => setAppMessagePush(e.target.checked)}
        />
      </Flex>

      <Flex mb="7px" alignItems="center">
        <Text flex="0 0 150px" mr="16px" fontWeight="bold">
          Enable RTMP Push
        </Text>
        <Checkbox
          isChecked={rtmpPush}
          id="rtmpPush"
          onChange={(e) => setRtmpPush(e.target.checked)}
        />
      </Flex>

      <Flex mb="7px" alignItems="center">
        <Text flex="0 0 150px" mr="16px" fontWeight="bold">
          Enable FTP Push
        </Text>
        <Checkbox
          isChecked={ftpPush}
          id="ftpPush"
          onChange={(e) => setFtpPush(e.target.checked)}
        />
      </Flex>

      <Flex mb="7px" alignItems="center">
        <Text flex="0 0 150px" mr="16px" fontWeight="bold">
          Minimum Duration(s)
        </Text>
        <Input
          type="text"
          placeholder="MinDuration"
          width="150px"
          padding="2px"
        />
      </Flex>

      <Flex mb="7px" alignItems="center">
        <Text flex="0 0 150px" mr="16px" fontWeight="bold">
          Sensitivity Level
        </Text>
        <Select
          width="150px"
          value={Sensitivity}
          onChange={(e) => setSensitivity(Number(e.target.value))}
        >
          {Array.from({ length: 11 }, (_, i) => (
            <option key={i} value={i}>
              {i}
            </option>
          ))}
        </Select>
      </Flex>

      <Button
        onClick={openCanvasModal}
        bg="#88ffcc"
        color="black"
        w={"fit-content"}
        borderRadius="4px"
      >
        Open Canvas
      </Button>

      <UAOCanvas
        isOpen={isCanvasModalOpen}
        onClose={closeCanvasModal}
        onCanvasData={handleCanvasData}
        existingCoordinates={DetectRegion}
        deviceId={deviceId}
      />

      <Button
        onClick={handleSave}
        bg="#7F56D9"
        color="white"
        w={"fit-content"}
        borderRadius="4px"
        fontWeight="bold"
        display={{ base: "none", md: "block" }}
      >
        Save
      </Button>
    </Box>
  );
}

export default UnattendBaggageDetection;
