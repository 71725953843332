import React from 'react'
import Video_Setup from './Video_Setup'
import Image_Setup from './Image_Setup'
import ISP_Setup from './ISP_Setup'
import OSD_Setup from './OSD_Setup'

function MobileViewMediaSettings({ deviceId, onSave }) {
  return (
    <div>
          <Video_Setup deviceId={deviceId} onSave={onSave} />
          <Image_Setup deviceId={deviceId} onSave={onSave} />
          {/* <ISP_Setup deviceId={deviceId} onSave={onSave}  />
          <OSD_Setup deviceId={deviceId} onSave={onSave}/> */}
    </div>
  )
}

export default MobileViewMediaSettings