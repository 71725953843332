import { useEffect, useState } from "react";
import withAuth from "../components/withAuth";
import { Box } from "@chakra-ui/react";
import styles from "./AI-Dashboard/Dashboard.module.css";
import axios from "axios";
import MapModel from "../components/Map_model/map";
const Geomap = () => {
  const [gpsData, setGpsData] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [url, setUrl] = useState('https://media2.arcisai.io:8443/hdl/DVR-30/RTSP-VSPL-117465-AXKAU.flv');
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setUrl(null);
    setModalOpen(false);
  };

  useEffect(() => {
    // Fetch GPS data from the backend
    const fetchGPSData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/geomap`);
        // console.log(response.data.data);
        // setGpsData(response.data.data); // Set the GPS data to state

        const filteredData = response.data.data.filter(
          (item) => item.name === "GJ38AA8632"
        );

        console.log(filteredData); // Check if filtered data is correct

        setGpsData(filteredData);
      } catch (error) {
        console.error("Error fetching GPS data:", error);
      }
    };

    fetchGPSData();
  }, []);

  useEffect(() => {
    const initMap = () => {
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: 20.5937, lng: 78.9629 }, // Center of India
        zoom: 5,
        styles: [
          {
            stylers: [{ saturation: -100 }, { lightness: 50 }],
          },
        ],
      });

      // Define the purple marker icon
      const purpleMarkerIcon = {
        url: "/bike.png",
        scaledSize: new window.google.maps.Size(40, 40),
      };

      // Add markers for each device
      gpsData.forEach((device) => {
        const { latitude, longitude, name } = device;
    
        // Create a new marker for each device
        const marker = new window.google.maps.Marker({
          position: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
          map,
          icon: purpleMarkerIcon,
          title: name, // Display the device name when hovered
        });
    
        marker.addListener('click', () => {
          setModalOpen(true)
          setSelectedDevice(device); // Set the selected device on marker click
        });
      });
    };

    // Initialize the map once gpsData is fetched and Google Maps API is loaded
    if (window.google && window.google.maps && gpsData.length > 0) {
      initMap();
    } else {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD2CF3PlGBd0tQhusHwX3ngfPaad0pmJ_Q&callback=initMap`;
      script.defer = true;
      script.async = true;

      script.onerror = () => {
        console.error("Error loading Google Maps API script");
      };

      document.head.appendChild(script);
    }
  }, [gpsData]); // Run this effect when gpsData changes

  return (
    <Box
      mr={{ base: "2%", md: "5%", lg: "9%" }}
      mt={"1%"}
      display={"flex"}
      flexDirection={"column"}
    >
      <MapModel isOpen={modalOpen}  url={url} onClose={closeModal} />
      <Box>
        <div className={styles.contentHeader}>
          <div>
            <h1 style={{ fontSize: "30px", fontWeight: "bold" }}>
              Geolocation
            </h1>
          </div>
        </div>
      </Box>
      <Box>
        <div id="map" style={{ height: "80vh", width: "100%" }}></div>
      </Box>
    </Box>
  );
};

export default Geomap;
