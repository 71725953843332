import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import styles from "../Settings.module.css";
import {
  Box,
  Checkbox,
  Button,
  Select,
  Flex,
  Stack,
  Text,
} from "@chakra-ui/react";

function FaceDetection({ deviceId }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [alarmSound, setAlarmSound] = useState(false);
  const [spOSD, setspOSD] = useState(false);
  const [Sensitivity, setSensitivity] = useState();

  const [whiteLightAlarm, setWhiteLightAlarm] = useState(false);
  const [appMessagePush, setAppMessagePush] = useState(false);
  const [rtmpPush, setRtmpPush] = useState(false);
  const [ftpPush, setFtpPush] = useState(false);
  const [error, setError] = useState(null);
  const [detectLine, setDetectLine] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/getFace`,
          {
            params: {
              deviceId: deviceId,
            },
          }
        );

        setIsEnabled(response.data.Enabled);
        setspOSD(response.data.spOSD);
        setSensitivity(response.data.Sensitivity);
        setRtmpPush(response.data.AlarmOut.RtmpPush.Enabled);
        setFtpPush(response.data.AlarmOut.FtpPush.Enabled);
        setAlarmSound(response.data.AlarmOut.AudioAlert.Enabled);
        setWhiteLightAlarm(response.data.AlarmOut.LightAlert.Enabled);
        setAppMessagePush(response.data.AlarmOut.AppPush.Enabled);
        setDetectLine(response.data.DetectAera);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]);

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/p2p/setFace`,
        {
          Enabled: isEnabled,
          spOSD: spOSD,
          Sensitivity: Sensitivity,
          AlarmOut: {
            AudioAlert: {
              Enabled: alarmSound,
            },
            LightAlert: {
              Enabled: whiteLightAlarm,
            },
            AppPush: {
              Enabled: appMessagePush,
            },
            RtmpPush: {
              Enabled: rtmpPush,
            },
            FtpPush: {
              Enabled: ftpPush,
            },
          },
        },
        {
          params: { deviceId: deviceId },
        }
      );

      console.log("Settings saved", response.data);
    } catch (error) {
      console.error("Error saving settings", error);
    }
  };
  return (
    <Box className={styles.notify}  marginLeft={'0px'} mt={{base:'5',md:0}}>
      <Text
        color="#7F56D9"
        fontSize={{ base: "20px", md: "24px" }}
        fontWeight="bold"
        mb={4}
        display={{ base: "none", md: "block" }}
      >
          Face Detection
        </Text>
      

      <Stack spacing={4}>
        <Flex align="center">
          <Text flex="0 0 150px" fontWeight="bold" mr={4}>
            Enabled
          </Text>
          <Checkbox
            isChecked={isEnabled}
            id="enabled"
            onChange={(e) => setIsEnabled(e.target.checked)}
          />
        </Flex>

        <Flex align="center">
          <Text flex="0 0 150px" fontWeight="bold" mr={4}>
            Enable Alarm Sound
          </Text>
          <Checkbox
            isChecked={alarmSound}
            id="alarmSound"
            onChange={(e) => setAlarmSound(e.target.checked)}
          />
        </Flex>

        <Flex align="center">
          <Text flex="0 0 150px" fontWeight="bold" mr={4}>
            Enable White Light Alarm Sound
          </Text>
          <Checkbox
            isChecked={whiteLightAlarm}
            id="whiteLightAlarm"
            onChange={(e) => setWhiteLightAlarm(e.target.checked)}
          />
        </Flex>

        <Flex align="center">
          <Text flex="0 0 150px" fontWeight="bold" mr={4}>
            Enable App Message Push
          </Text>
          <Checkbox
            isChecked={appMessagePush}
            id="messagePush"
            onChange={(e) => setAppMessagePush(e.target.checked)}
          />
        </Flex>

        <Flex align="center">
          <Text flex="0 0 150px" fontWeight="bold" mr={4}>
            Enable RTMP Push
          </Text>
          <Checkbox
            isChecked={rtmpPush}
            id="enableRTMPPush"
            onChange={(e) => setRtmpPush(e.target.checked)}
          />
        </Flex>

        <Flex align="center">
          <Text flex="0 0 150px" fontWeight="bold" mr={4}>
            Enable FTP Push
          </Text>
          <Checkbox
            isChecked={ftpPush}
            id="enableFTPPush"
            onChange={(e) => setFtpPush(e.target.checked)}
          />
        </Flex>

        <Flex align="center">
          <Text flex="0 0 150px" fontWeight="bold" mr={4}>
            Face Draw Region
          </Text>
          <Checkbox
            isChecked={spOSD}
            id="spOSD"
            onChange={(e) => setspOSD(e.target.checked)}
          />
        </Flex>

        <Flex align="center">
          <Text flex="0 0 150px" fontWeight="bold" mr={4}>
            Sensitivity Level
          </Text>
          <Select
            value={Sensitivity}
            onChange={(e) => setSensitivity(Number(e.target.value))}
            width="100px"
          >
            {Array.from({ length: 11 }, (_, i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </Select>
        </Flex>

        <Box textAlign="center">
          <Button
            bgColor="#7F56D9"
            color="white"
            px={6}
            py={3}
            borderRadius="4px"
            fontWeight="bold"
            onClick={handleSave}
            display={{ base: "none", md: "block" }}
          >
            Save
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default FaceDetection;