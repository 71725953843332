import React, { useEffect, useState } from 'react';
import { getSignedUrl } from '../../services/awsConfig';
import Slider from "react-slick";
import { Box, Image, Card, CardBody, Grid } from "@chakra-ui/react";

function AiImage({ imageKey }) {
  const [imageURL, setImageURL] = useState([]);
  console.log('imageurl in aiimage', imageURL)
  useEffect(() => {
    const fetchImageURL = async () => {
      const url = await getSignedUrl(imageKey);
      setImageURL(url);
    };

    fetchImageURL();
  }, [imageKey]);


  return (
    <div >
      {imageURL ? (
        <img src={`${imageURL}`} alt="ArcisAI Image" style={{ maxWidth: '100%', height: 'auto' }} />
      ) : (
        'Loading...'
      )}
    </div>
  );
}

export default AiImage;