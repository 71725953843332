import React, { useEffect, useState } from "react";

import styles from "../Settings.module.css";
import axios from "axios";
import {
  Box,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";

function HumanDetection({ deviceId }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [alarmSound, setAlarmSound] = useState(false);
  const [spOSD, setspOSD] = useState(false);
  const [Sensitivity, setSensitivity] = useState();

  const [whiteLightAlarm, setWhiteLightAlarm] = useState(false);
  const [appMessagePush, setAppMessagePush] = useState(false);
  const [rtmpPush, setRtmpPush] = useState(false);
  const [ftpPush, setFtpPush] = useState(false);
  const [error, setError] = useState(null);
  const [detectLine, setDetectLine] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/getHumanoid`,
          {
            params: {
              deviceId: deviceId,
            },
          }
        );

        setIsEnabled(response.data.Enabled);
        setspOSD(response.data.spOSD);
        setSensitivity(response.data.Sensitivity);
        setRtmpPush(response.data.AlarmOut.RtmpPush.Enabled);
        setFtpPush(response.data.AlarmOut.FtpPush.Enabled);
        setAlarmSound(response.data.AlarmOut.AudioAlert.Enabled);
        setWhiteLightAlarm(response.data.AlarmOut.LightAlert.Enabled);
        setAppMessagePush(response.data.AlarmOut.AppPush.Enabled);
        setDetectLine(response.data.DetectAera);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]);

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/p2p/setHumanoid`,
        {
          Enabled: isEnabled,
          spOSD: spOSD,
          Sensitivity: Sensitivity,
          AlarmOut: {
            AudioAlert: {
              Enabled: alarmSound,
            },
            LightAlert: {
              Enabled: whiteLightAlarm,
            },
            AppPush: {
              Enabled: appMessagePush,
            },
            RtmpPush: {
              Enabled: rtmpPush,
            },
            FtpPush: {
              Enabled: ftpPush,
            },
          },
        },
        {
          params: { deviceId: deviceId },
        }
      );

      console.log("Settings saved", response.data);
    } catch (error) {
      console.error("Error saving settings", error);
    }
  };

  return (
    <Box
    className={styles.notify}  marginLeft={'0px'} mt={{base:'5',md:0}} // Add some margin at the top
    >
      <Text
        color="#7F56D9"
        fontSize={{ base: "20px", md: "24px" }}
        fontWeight="bold"
        mb={4}
        display={{ base: "none", md: "block" }}
      >
        Human Detection
      </Text>

      <Stack spacing={4}>
        {/** Enabled Toggle */}
        <FormControl display="flex" alignItems="center">
          <FormLabel
            htmlFor="enabled"
            fontWeight="bold"
            mr={4}
            width={{ base: "auto", md: "150px" }}
          >
            Enabled
          </FormLabel>
          <Checkbox
            id="enabled"
            isChecked={isEnabled}
            onChange={(e) => setIsEnabled(e.target.checked)}
          />
        </FormControl>

        {/** Enable Alarm Sound Toggle */}
        <FormControl display="flex" alignItems="center">
          <FormLabel
            htmlFor="alarmSound"
            fontWeight="bold"
            mr={4}
            width={{ base: "auto", md: "150px" }}
          >
            Enable Alarm Sound
          </FormLabel>
          <Checkbox
            id="alarmSound"
            isChecked={alarmSound}
            onChange={(e) => setAlarmSound(e.target.checked)}
          />
        </FormControl>

        {/** Enable White Light Alarm Toggle */}
        <FormControl display="flex" alignItems="center">
          <FormLabel
            htmlFor="whiteLight"
            fontWeight="bold"
            mr={4}
            width={{ base: "auto", md: "150px" }}
          >
            Enable White Light Alarm
          </FormLabel>
          <Checkbox
            id="whiteLight"
            isChecked={whiteLightAlarm}
            onChange={(e) => setWhiteLightAlarm(e.target.checked)}
          />
        </FormControl>

        {/** Enable App Message Push Toggle */}
        <FormControl display="flex" alignItems="center">
          <FormLabel
            htmlFor="messagePush"
            fontWeight="bold"
            mr={4}
            width={{ base: "auto", md: "150px" }}
          >
            Enable App Message Push
          </FormLabel>
          <Checkbox
            id="messagePush"
            isChecked={appMessagePush}
            onChange={(e) => setAppMessagePush(e.target.checked)}
          />
        </FormControl>

        {/** Enable RTMP Push Toggle */}
        <FormControl display="flex" alignItems="center">
          <FormLabel
            htmlFor="enableRTMPPush"
            fontWeight="bold"
            mr={4}
            width={{ base: "auto", md: "150px" }}
          >
            Enable RTMP Push
          </FormLabel>
          <Checkbox
            id="enableRTMPPush"
            isChecked={rtmpPush}
            onChange={(e) => setRtmpPush(e.target.checked)}
          />
        </FormControl>

        {/** Enable FTP Push Toggle */}
        <FormControl display="flex" alignItems="center">
          <FormLabel
            htmlFor="enableFTPPush"
            fontWeight="bold"
            mr={4}
            width={{ base: "auto", md: "150px" }}
          >
            Enable FTP Push
          </FormLabel>
          <Checkbox
            id="enableFTPPush"
            isChecked={ftpPush}
            onChange={(e) => setFtpPush(e.target.checked)}
          />
        </FormControl>

        {/** Human Draw Region Toggle */}
        <FormControl display="flex" alignItems="center">
          <FormLabel
            htmlFor="humanDrawRegion"
            fontWeight="bold"
            mr={4}
            width={{ base: "auto", md: "150px" }}
          >
            Human Draw Region
          </FormLabel>
          <Checkbox
            id="humanDrawRegion"
            isChecked={spOSD}
            onChange={(e) => setspOSD(e.target.checked)}
          />
        </FormControl>

        {/** Sensitivity Level Dropdown */}
        <FormControl display="flex" alignItems="center">
          <FormLabel
            htmlFor="sensitivity"
            fontWeight="bold"
            mr={4}
            width={{ base: "auto", md: "150px" }}
          >
            Sensitivity Level
          </FormLabel>
          <Select
            id="sensitivity"
            value={Sensitivity}
            onChange={(e) => setSensitivity(Number(e.target.value))}
            width={{ base: "auto", md: "150px" }} // Responsive width
            mr={2}
          >
            {Array.from({ length: 11 }, (_, i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </Select>
        </FormControl>

        {/** Save Button */}
        <Button
          onClick={handleSave}
          bgColor="#7F56D9"
          color="white"
          py={2}
          px={4}
          borderRadius="4px"
          fontWeight="bold"
          _hover={{ bgColor: "#6E44CC" }}
          width={{ base: "100%", md: "auto" }} // Full width on mobile, auto on larger screens
          alignSelf="flex-start" // Align the button to the left on larger screens
          display={{ base: "none", md: "block" }}
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default HumanDetection;