import React, { useEffect, useState } from "react";
import axios from "axios";
import LineCrossCanvas from "../../LineCrossCanvas";
import styles from "../Settings.module.css";
import { Checkbox, Button, Flex, Box, Text, Select } from "@chakra-ui/react";

function LineCrossingDetection({ deviceId }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [alarmSound, setAlarmSound] = useState(false);
  const [whiteLightAlarm, setWhiteLightAlarm] = useState(false);
  const [appMessagePush, setAppMessagePush] = useState(false);
  const [rtmpPush, setRtmpPush] = useState(false);
  const [ftpPush, setFtpPush] = useState(false);
  const [direction, setDirection] = useState("");
  const [error, setError] = useState(null);
  const [detectLine, setDetectLine] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/getlinecross`,
          {
            params: {
              deviceId: deviceId,
            },
          }
        );

        setIsEnabled(response.data.Enabled);
        setRtmpPush(response.data.AlarmOut.RtmpPush.Enabled);
        setFtpPush(response.data.AlarmOut.FtpPush.Enabled);
        setAlarmSound(response.data.AlarmOut.AudioAlert.Enabled);
        setWhiteLightAlarm(response.data.AlarmOut.LightAlert.Enabled);
        setAppMessagePush(response.data.AlarmOut.AppPush.Enabled);
        setDirection(response.data.Direction);
        // console.log(response.data.Direction)
        setDetectLine(response.data.DetectLine);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]);

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/p2p/setlinecross`,
        {
          Enabled: isEnabled,
          DetectLine: detectLine,
          DetectObj: "Human",
          Direction: direction,
          AlarmOut: {
            AudioAlert: {
              Enabled: alarmSound,
            },
            LightAlert: {
              Enabled: whiteLightAlarm,
            },
            AppPush: {
              Enabled: appMessagePush,
            },
            RtmpPush: {
              Enabled: rtmpPush,
            },
            FtpPush: {
              Enabled: ftpPush,
            },
          },
        },
        {
          params: { deviceId: deviceId },
        }
      );

      console.log("Settings saved", response.data);
    } catch (error) {
      console.error("Error saving settings", error);
    }
  };

  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const openCanvasModal = () => {
    setIsCanvasModalOpen(true);
  };

  const closeCanvasModal = () => {
    setIsCanvasModalOpen(false);
  };

  const [isCanvasModalOpen, setIsCanvasModalOpen] = useState(false);
  const handleCanvasData = (line, direction) => {
    setDetectLine(line);
    setDirection(direction);
  };

  return (
    <Box className={styles.notify}  marginLeft={'0px'} mt={{base:'5',md:0}}>
      <Text
        color="#7F56D9"
        fontSize={{ base: "20px", md: "24px" }}
        fontWeight="bold"
        mb={4}
        display={{ base: "none", md: "block" }}
      >
        Line Crossing Detection
      </Text>

      <Flex mb={4} alignItems="center">
        <Text flex="0 0 150px" mr={4} fontWeight="bold">
          Enabled
        </Text>
        <Checkbox
          isChecked={isEnabled}
          onChange={(e) => setIsEnabled(e.target.checked)}
        />
      </Flex>

      <Flex mb={4} alignItems="center">
        <Text flex="0 0 150px" mr={4} fontWeight="bold">
          Enable Alarm Sound
        </Text>
        <Checkbox
          isChecked={alarmSound}
          onChange={(e) => setAlarmSound(e.target.checked)}
        />
      </Flex>

      <Flex mb={4} alignItems="center">
        <Text flex="0 0 150px" mr={4} fontWeight="bold">
          Enable White Light Alarm Sound
        </Text>
        <Checkbox
          isChecked={whiteLightAlarm}
          onChange={(e) => setWhiteLightAlarm(e.target.checked)}
        />
      </Flex>

      <Flex mb={4} alignItems="center">
        <Text flex="0 0 150px" mr={4} fontWeight="bold">
          Enable App Message Push
        </Text>
        <Checkbox
          isChecked={appMessagePush}
          onChange={(e) => setAppMessagePush(e.target.checked)}
        />
      </Flex>

      <Flex mb={4} alignItems="center">
        <Text flex="0 0 150px" mr={4} fontWeight="bold">
          Enable RTMP Push
        </Text>
        <Checkbox
          isChecked={rtmpPush}
          onChange={(e) => setRtmpPush(e.target.checked)}
        />
      </Flex>

      <Flex mb={4} alignItems="center">
        <Text flex="0 0 150px" mr={4} fontWeight="bold">
          Enable FTP Push
        </Text>
        <Checkbox
          isChecked={ftpPush}
          onChange={(e) => setFtpPush(e.target.checked)}
        />
      </Flex>

      <Button
        colorScheme="purple"
        size="md"
        mb={4}
        display={{ base: "none", md: "block" }}
        w="fit-content"
        onClick={handleSave}
      >
        Save Line Crossing Detection Settings
      </Button>

      <Button
        bg="#88ffcc"
        color="black"
        size="md"
        w={"fit-content"}
        // display={{base:'none',md:'block'}}
        onClick={openCanvasModal}
      >
        Open Canvas
      </Button>

      <LineCrossCanvas
        isOpen={isCanvasModalOpen}
        onClose={closeCanvasModal}
        onCanvasData={handleCanvasData}
        existingCoordinates={detectLine}
        existingDirection={direction}
        deviceId={deviceId}
      />
    </Box>
  );
}

export default LineCrossingDetection;