import React, { useState, useEffect } from "react";
import styles from "./Settings.module.css";
import { IoSettingsOutline, IoWifi } from "react-icons/io5";
import { LuBadgeHelp } from "react-icons/lu";
import { MdContentCopy } from "react-icons/md";
import dayjs from "dayjs";

import {
  Box,
  Text,
  Switch,
  Input,
  Select,
  Button,
  VStack,
  HStack,
  Grid,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getQuality } from "../actions/settingsActions";

function General({ device, onSave }) {
  const [bEnable, setBEnable] = useState(true);
  const [audioEnable, setAudioEnable] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cameraName, setCameraName] = useState();
  // New Add to Add Time Zone In The General Page
  const [timeSettings, setTimeSettings] = useState("");
  const [timeZoneOffset, setTimeZoneOffset] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/time-settings`,
          {
            params: {
              deviceid: device.deviceid,
            },
          }
        );
        const apiDateTime = response.data; // Assuming the API response is a single date-time string
        const formattedDateTime = dayjs(apiDateTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        setTimeSettings(formattedDateTime);
        const timeZoneOffset = apiDateTime.slice(-6);
        setTimeZoneOffset(timeZoneOffset);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  }, []);

  const fetchAlertSettings = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/p2p/getAlertSetting`,
        {
          params: { deviceId: device.deviceid },
        }
      );

      setBEnable(response.data.bEnable);
    } catch (error) {
      setError("Error fetching alert settings");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const setAlertSetting = async (newBEnable) => {
    try {
      setLoading(true);
      setError(null);

      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/p2p/setAlertSetting`,
        {
          bEnable: newBEnable,
        },
        {
          params: { deviceId: device.deviceid },
        }
      );

      // console.log("Notification setting updated successfully");
    } catch (err) {
      setError("Failed to update the notification settings");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = () => {
    const newBEnable = !bEnable;
    setBEnable(newBEnable);
    setAlertSetting(newBEnable);
  };

  const handleAudioToggle = () => {
    const newAudioEnable = !audioEnable;
    setAudioEnable(newAudioEnable);
  };

  useEffect(() => {
    fetchAlertSettings();
  }, [device.deviceid]);

  const reboot = async () => {
    try {
      setLoading(true);
      setError(null);

      await axios.put(`${process.env.REACT_APP_BASE_URL}/p2p/reboot`, {
        deviceId: device.deviceid,
      });

      // console.log("Reboot successfully");
    } catch (err) {
      setError("Failed to Reboot");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const [quality, setQuality] = useState(""); // Default empty or a preset value

  const fetchData = async () => {
    try {
      const qualityResponse = await getQuality(device.deviceid);
      if (qualityResponse.data && qualityResponse.data.quality) {
        setQuality(qualityResponse.data.quality); // Set quality from the response
      } else {
        setQuality("high"); // Default to 'high' if no quality found
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [device.deviceid]);

  const handleQualityChange = async (e) => {
    const selectedQuality = e.target.value;
    setQuality(selectedQuality);

    // Automatically trigger the submission process with the selected quality
    await handleSubmit(selectedQuality);
  };

  const handleSubmit = async (selectedQuality) => {
    setLoading(true); // Start loading spinner or state

    try {
      // Send request to backend API to update quality
      await axios.put(`${process.env.REACT_APP_BASE_URL}/p2p/updateQuality`, {
        quality: selectedQuality, // Use the parameter value directly
        deviceid: device.deviceid, // Pass the selected device ID
      });

      fetchData(); // Fetch updated quality after successful submission

      // Display success message with selected quality
      toast.success(`Video quality changed to ${selectedQuality}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      // Display error message if submission fails
      toast.error("Failed to change video quality", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false); // Stop loading spinner or state
    }
  };

  return (
    <Box width="100%">
      <ToastContainer />
      <VStack align="start" spacing={4} mb={4} color="#7F56D9">
        <Text fontSize={{ base: "20px", md: "24px" }} fontWeight="bold">
          General Setting
        </Text>
      </VStack>

      <Grid templateColumns={{ base: "1fr 2fr", md: "1fr,2fr" }} gap={6} mb={4}>
        <Text fontSize={{ base: "16px", md: "18px" }} fontWeight="bold">
          AI-Notification
        </Text>
        <Switch
          id="motionDetectionAlert"
          isChecked={bEnable}
          onChange={handleToggle}
          isDisabled={loading}
          width={'10%'}
        />
      </Grid>

      {/* <Grid templateColumns={{ base: "1fr 2fr", md: "1fr 2fr" }} gap={6} mb={4}>
        <Text fontSize={{ base: "16px", md: "18px" }} fontWeight="bold">
          Push Notification
        </Text>
        <Switch id="pushNotification" />
      </Grid> */}

      <Text
        color="#7F56D9"
        fontWeight="bold"
        fontSize={{ base: "16px", md: "18px" }}
        mb={4}
      >
        Device Settings
      </Text>

      <Grid templateColumns={{ base: "1fr 2fr", md: "1fr 2fr" }} gap={6} mb={4}>
        <Text fontSize={{ base: "16px", md: "18px" }} fontWeight="bold">
          Camera Name
        </Text>
        <Input
          id="cameraName"
          placeholder="Enter Camera Name"
          value={cameraName}
          disabled
          variant="outline"
          width="full" // Full width on mobile
        />
      </Grid>

      <Grid templateColumns={{ base: "1fr 2fr", md: "1fr 2fr" }} gap={6} mb={4}>
        <Text fontSize={{ base: "16px", md: "18px" }} fontWeight="bold">
          Time Zone
        </Text>
        <Select
          id="timezone"
          value={timeZoneOffset}
          width={{ base: "full", md: "auto" }}
        >
          {[
            "-12:00",
            "-11:00",
            "-10:00",
            "-09:00",
            "-08:00",
            "-07:00",
            "-06:00",
            "-05:00",
            "-04:00",
            "-03:00",
            "-02:00",
            "-01:00",
            "+00:00",
            "+01:00",
            "+02:00",
            "+03:00",
            "+04:00",
            "+05:00",
            "+05:30",
            "+06:00",
            "+07:00",
            "+08:00",
            "+09:00",
            "+10:00",
            "+11:00",
            "+12:00",
          ].map((tz) => (
            <option key={tz} value={tz}>{`GMT ${tz}`}</option>
          ))}
        </Select>
      </Grid>

      <Grid templateColumns={{ base: "1fr 2fr", md: "1fr,2fr" }} gap={6} mb={4}>
        <Text fontSize={{ base: "16px", md: "18px" }} fontWeight="bold">
          Audio
        </Text>
        <Switch
          id="motionDetectionAlert"
          isChecked={audioEnable}
          onChange={handleAudioToggle}
          isDisabled={loading}
          width={'10%'}
        />
      </Grid>

      <Grid templateColumns={{ base: "1fr 2fr", md: "1fr,2fr" }} gap={6} mb={4}>
        <Text fontSize={{ base: "16px", md: "18px" }} fontWeight="bold">
          Quality
        </Text>
        <Select
          value={quality}
          onChange={handleQualityChange}
          size="lg"
          width={{ base: "100%", md: "200px" }}
          disabled={loading}
          fontSize={{ base: "14px", md: "16px" }}
        >
          <option value="low">Low</option>
          <option value="mid">Mid</option>
          <option value="high">High</option>
        </Select>
      </Grid>

      {/* <Grid templateColumns={{ base: "1fr 2fr", md: "1fr 2fr" }} gap={6} mb={4}>
        <Text fontSize={{ base: "16px", md: "18px" }} fontWeight="bold">
          Screen and Sound
        </Text>
        <Select
          id="screenSound"
          placeholder="Select option"
          variant="outline"
          width={{ base: "full", md: "auto" }} // Full width on mobile
        >
          <option value="uninterruptedRecording">
            Uninterrupted recording
          </option>
          <option value="interruptedRecording">Interrupted recording</option>
        </Select>
      </Grid>

      <Grid templateColumns={{ base: "1fr 2fr", md: "1fr 2fr" }} gap={6} mb={4}>
        <Text fontSize={{ base: "16px", md: "18px" }} fontWeight="bold">
          PTZ Control
        </Text>
        <Switch id="ptzControl" />
      </Grid>

      <Grid templateColumns={{ base: "1fr 2fr", md: "1fr 2fr" }} gap={6} mb={4}>
        <Text fontSize={{ base: "16px", md: "18px" }} fontWeight="bold">
          Storage Settings
        </Text>
        <Select
          id="storageSettings"
          placeholder="Select option"
          variant="outline"
          width={{ base: "full", md: "auto" }} // Full width on mobile
        >
          <option value="autoSaveToCloud">Auto Save to Cloud</option>
          <option value="localSaveOnly">Local Save Only</option>
        </Select>
      </Grid> */}

      <HStack spacing={4} mt={4} justifyContent="flex-start">
        <Button
          bg="#EC2218"
          color="white"
          px={6}
          py={3}
          borderRadius="md"
          fontWeight="bold"
          onClick={reboot}
          width={{ base: "30%", md: "auto" }} // Full width on mobile
        >
          Reboot
        </Button>
      </HStack>
    </Box>
  );
}

export default General;