import React, { useState, useRef } from "react";
import styles from "./Settings.module.css";
import { IoSettingsOutline, IoWifi } from "react-icons/io5";
import { LuBadgeHelp } from "react-icons/lu";
import { MdContentCopy } from "react-icons/md";
import General from "./General";
import Media from "./Media-Setting/Media";
import EdgeAi from "./EdgeAi";
import Network from "./Network";
import SystemSetting from "./SystemSetting";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Image,
  List,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  useBreakpoint,
  VStack,
} from "@chakra-ui/react";

function Settings({ device, onClose }) {
  const [activeTab, setActiveTab] = useState("General");
  const [selectedMedia, setSelectedMedia] = useState("Image Setting"); // State to track selected item
  const [selectedAi, setSelectedAi] = useState("Motion Detection"); // State to track selected item

  const [selectedNetwork, setSelectedNetwork] = useState("Local Setup"); // State to track selected item

  const [selectedSystemSetting, setSelectedSystemSetting] = useState("Time"); // State to track selected item

  const handleItemClick = (mediaType) => {
    setSelectedMedia(mediaType); // Update state with clicked item
  };

  // Handler for clicking a list item
  const handleAiItemClick = (AiType) => {
    setSelectedAi(AiType); // Update state with clicked item
  };

  const handleNetworkItemClick = (networkType) => {
    setSelectedNetwork(networkType); // Update state with clicked item
  };

  const handleSystemItemClick = (systemType) => {
    setSelectedSystemSetting(systemType); // Update state with clicked item
  };
  // Refs for save functions from child components
  const saveGeneralRef = useRef(null);
  const saveMediaRef = useRef(null);
  const saveAiRef = useRef(null);
  const saveNetworkRef = useRef(null);
  const saveSystemSettingsRef = useRef(null);

  const handleSave = () => {
    if (activeTab === "General" && saveGeneralRef.current) {
      saveGeneralRef.current();
    } else if (activeTab === "Media" && saveMediaRef.current) {
      saveMediaRef.current();
    } else if (activeTab === "AI" && saveAiRef.current) {
      saveAiRef.current();
    } else if (activeTab === "Network" && saveNetworkRef.current) {
      saveNetworkRef.current();
    } else if (activeTab === "System" && saveSystemSettingsRef.current) {
      saveSystemSettingsRef.current();
    }
  };
  const renderTabContent = () => {
    switch (activeTab) {
      case "General":
        return (
          <General
            device={device}
            onSave={(saveFunc) => (saveGeneralRef.current = saveFunc)}
          />
        );
      case "Media":
        return (
          <Media
            selectedMedia={selectedMedia}
            deviceId={device.deviceid}
            onSave={(saveFunc) => (saveMediaRef.current = saveFunc)}
          />
        );
      case "AI":
        return (
          <EdgeAi
            selectedAi={selectedAi}
            device={device.deviceid}
            onSave={(saveFunc) => (saveAiRef.current = saveFunc)}
          />
        );
      case "Network":
        return (
          <Network
            selectedMedia={selectedNetwork}
            deviceId={device.deviceid}
            onSave={(saveFunc) => (saveNetworkRef.current = saveFunc)}
          />
        );
      case "System":
        return (
          <SystemSetting
            selectedSystemSetting={selectedSystemSetting}
            deviceId={device.deviceid}
            onSave={(saveFunc) => (saveSystemSettingsRef.current = saveFunc)}
          />
        );
      default:
        return <div>Select a tab</div>;
    }
  };

  const renderCameraInfo = () => {
    switch (activeTab) {
      case "General":
        return (
          <>
            <Flex
              direction={{ base: "row", md: "column" }} // Change to column for mobile view
              align={{ base: "start", md: "flex-start" }}
              textAlign={{ base: "center", md: "left" }}
              wrap="wrap"
              width="100%"
            >
              <Image
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a31f072c5a476878b51af8d11d2a0789852947bb4cf5e6068593eb7fceb52043?apiKey=21c2172730ed406bb6f91788633e80d1&"
                alt="Camera"
                width={{ base: "40%", md: "50%" }} // Full width on mobile
                height={{ base: "60%", md: "auto" }}
                mr={{ base: 0, md: 0 }}
                mt={{ base: 2 }}
                mb={4}
              />

              <Box
                flex={{ base: 1, md: 1 }}
                textAlign={{ base: "center", md: "left" }}
                ml={{ base: 4, md: 0 }}
              >
                <Text fontSize="sm" opacity={0.8}>
                  <strong>Camera Model:</strong> S-Series
                </Text>
                <Text fontSize="lg" opacity={0.8}>
                  <Text as="span" display={{ base: "none", md: "inline" }}>
                    SNumber :
                  </Text>{" "}
                  {device.deviceid}
                </Text>
                <Text
                  fontSize="lg"
                  opacity={0.8}
                  display={{ base: "none", md: "block" }}
                >
                  <strong>Signal :</strong> Strong {/* Hidden in mobile view */}
                </Text>
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  opacity={{ base: 0.4, md: 0.8 }}
                >
                  <strong>Firmware:</strong> v12.97630
                </Text>
                <Text
                  fontSize="lg"
                  opacity={0.8}
                  display={{ base: "block", md: "none" }}
                  mt={2}
                  ml={"-150px"}
                >
                  <strong>Signal :</strong> Strong
                  {/* Displayed only in mobile view */}
                </Text>
              </Box>
            </Flex>
          </>
        );
      case "Media":
        return (
          <>
            {/* <Box display={{ base: "none", md: "block" }}>
              <List className={styles.mediaList} spacing={3}>
                <ListItem
                  onClick={() => handleItemClick("Video Setting")}
                  cursor="pointer"
                  _hover={{ color: "purple.500" }}
                >
                  General
                </ListItem>
                <ListItem
                  onClick={() => handleItemClick("Image Setting")}
                  cursor="pointer"
                  _hover={{ color: "purple.500" }}
                >
                  Video Setting
                </ListItem>
              </List>
            </Box> */}
            <Flex
              direction={{ base: "row", md: "column" }} // Change to column for mobile view
              align={{ base: "start", md: "flex-start" }}
              textAlign={{ base: "center", md: "left" }}
              wrap="wrap"
              width="100%"
            >
              <Image
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a31f072c5a476878b51af8d11d2a0789852947bb4cf5e6068593eb7fceb52043?apiKey=21c2172730ed406bb6f91788633e80d1&"
                alt="Camera"
                width={{ base: "40%", md: "50%" }} // Full width on mobile
                height={{ base: "60%", md: "auto" }}
                mr={{ base: 0, md: 0 }}
                mt={{ base: 2 }}
                mb={4}
              />

              <Box
                flex={{ base: 1, md: 1 }}
                textAlign={{ base: "center", md: "left" }}
                ml={{ base: 4, md: 0 }}
              >
                <Text fontSize="sm" opacity={0.8}>
                  <strong>Camera Model:</strong> S-Series
                </Text>
                <Text fontSize="lg" opacity={0.8}>
                  <Text as="span" display={{ base: "none", md: "inline" }}>
                    SNumber :
                  </Text>{" "}
                  {device.deviceid}
                </Text>
                <Text
                  fontSize="lg"
                  opacity={0.8}
                  display={{ base: "none", md: "block" }}
                >
                  <strong>Signal :</strong> Strong {/* Hidden in mobile view */}
                </Text>
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  opacity={{ base: 0.4, md: 0.8 }}
                >
                  <strong>Firmware:</strong> v12.97630
                </Text>
                <Text
                  fontSize="lg"
                  opacity={0.8}
                  display={{ base: "block", md: "none" }}
                  mt={2}
                  ml={"-150px"}
                >
                  <strong>Signal :</strong> Strong
                  {/* Displayed only in mobile view */}
                </Text>
              </Box>
            </Flex>
          </>
        );
      case "AI":
        return (
          <>
            <Box display={{ base: "none", md: "block" }} p={0}>
              <List className={styles.mediaList} spacing={3}>
                <ListItem
                  onClick={() => handleAiItemClick("Motion Detection")}
                  cursor="pointer"
                  _hover={{ color: "purple.500" }} // Change color on hover
                >
                  Motion Detection
                </ListItem>
                <ListItem
                  onClick={() => handleAiItemClick("Human Detection")}
                  cursor="pointer"
                  _hover={{ color: "purple.500" }} // Change color on hover
                >
                  Human Detection
                </ListItem>
                <ListItem
                  onClick={() => handleAiItemClick("Face Detection")}
                  cursor="pointer"
                  _hover={{ color: "purple.500" }} // Change color on hover
                >
                  Face Detection
                </ListItem>
                <ListItem
                  onClick={() => handleAiItemClick("Line Crossing Detection")}
                  cursor="pointer"
                  _hover={{ color: "purple.500" }} // Change color on hover
                >
                  Line Crossing Detection
                </ListItem>
                <ListItem
                  onClick={() => handleAiItemClick("Area Detection")}
                  cursor="pointer"
                  _hover={{ color: "purple.500" }} // Change color on hover
                >
                  Area Detection
                </ListItem>
                <ListItem
                  onClick={() => handleAiItemClick("Traffic Statistics")}
                  cursor="pointer"
                  _hover={{ color: "purple.500" }} // Change color on hover
                >
                  Traffic Statistics
                </ListItem>
                <ListItem
                  onClick={() =>
                    handleAiItemClick("Unattended Baggage Detection")
                  }
                  cursor="pointer"
                  _hover={{ color: "purple.500" }} // Change color on hover
                >
                  Unattended Baggage Detection
                </ListItem>
                <ListItem
                  onClick={() => handleAiItemClick("Missing Object Detection")}
                  cursor="pointer"
                  _hover={{ color: "purple.500" }} // Change color on hover
                >
                  Missing Object Detection
                </ListItem>
              </List>
              <Text color="green.500" mt={4}>
                Connected
              </Text>
            </Box>
          </>
        );
      // case "Network":
      //   return (
      //     <>
      //       <Box
      //         className={styles.networkInfo}
      //         mb="4"
      //         display={{ base: "none", md: "block" }}
      //       >
      //         <Text>
      //           <strong>Network Type</strong>WI-FI
      //           <br />
      //           <strong>Signal Strength:</strong>Excellent{" "}
      //           <IoWifi style={{ display: "inline" }} />
      //           <br />
      //           <strong>IP Address:</strong>192.169.1.100
      //           <br />
      //           <Text color="green.500" mt={3}>
      //             Network Connected
      //           </Text>
      //         </Text>
      //       </Box>

      //       <List
      //         className={styles.mediaList}
      //         spacing={3}
      //         display={{ base: "none", md: "block" }}
      //       >
      //         <ListItem
      //           onClick={() => handleNetworkItemClick("Local Setup")}
      //           cursor="pointer"
      //           _hover={{ color: "purple.500" }}
      //         >
      //           Local Setup
      //         </ListItem>
      //         {/* 
      //   <ListItem onClick={() => handleNetworkItemClick("RTMP Setup")} cursor="pointer">
      //     RTMP Setup
      //   </ListItem>
      //   <ListItem onClick={() => handleNetworkItemClick("FTP Setting")} cursor="pointer">
      //     FTP Setting
      //   </ListItem>
      //   */}
      //         <ListItem
      //           onClick={() => handleNetworkItemClick("4G Network Settings")}
      //           cursor="pointer"
      //           _hover={{ color: "purple.500" }}
      //         >
      //           4G Network Settings
      //         </ListItem>
      //       </List>

      //       <Text color="green" display={{ base: "none", md: "block" }}>
      //         Connected
      //       </Text>
      //     </>
      //   );
      // case "System":
      //   return (
      //     <VStack align="start" display={{ base: "none", md: "block" }}>
      //       <Box className={styles.systemInfo}>
      //         <Text>
      //           <strong>System Uptime:</strong> 12 days <br />
      //           <strong>Firmware Version:</strong> v12.97630 <br />
      //           <strong>Last Update:</strong> 5 days ago <br />
      //           <Text color="green.500">System healthy</Text>
      //         </Text>
      //       </Box>

      //       <List className={styles.mediaList} spacing={2}>
      //         <ListItem
      //           cursor="pointer"
      //           _hover={{ color: "purple.500" }}
      //           onClick={() => handleSystemItemClick("Time")}
      //         >
      //           Time
      //         </ListItem>
      //         <ListItem
      //           cursor="pointer"
      //           _hover={{ color: "purple.500" }}
      //           onClick={() => handleSystemItemClick("Device Info")}
      //         >
      //           Device Info
      //         </ListItem>
      //       </List>

      //       <Text color="green.500" mt={4}>
      //         Connected
      //       </Text>
      //     </VStack>
      //   );
      default:
        return (
          <p className={styles.cameraInfo}>
            <strong>Camera Model:</strong> YZ 22097 <br />
            <strong>Serial Number:</strong> YKAA-027648-B6DKJ{" "}
            <MdContentCopy style={{ display: "inline" }} /> <br />
            <strong>Signal:</strong> Strong{" "}
            <IoWifi style={{ display: "inline" }} /> <br />
            <strong>Firmware:</strong> v12.97630 <br />
            <span style={{ color: "green" }}>Connected</span>
          </p>
        );
    }
  };

  return (
    <Box width="100%" p={0} mt={4}>
      {/* Header Section */}
      {/* <Box
        display={{ base: "none", md: "flex" }}
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        // px={2} // Adjusted padding for the header
      >
        <Heading as="h6" size="md" color="black" fontWeight="bold">
          Godown No 11 Gate &nbsp;
          <b style={{ fontWeight: "lighter", fontSize: "12px" }}>11:55 55 PM</b>
        </Heading>
        <Box display="flex" gap={2}>
          <IoSettingsOutline color="blue.400" />
          <LuBadgeHelp />
        </Box>
      </Box> */}

      {/* Mobile Tabs Section */}
      <Box
        display={{ base: "block", md: "none" }} // Show on mobile view only
        maxWidth="100%"
        mx="auto" // Center the box horizontally
        
      >
        <Tabs
        p={0}
          variant="unstyled"
          onChange={(index) =>
            setActiveTab(["General", "Media", "AI"][index])
          }
        >
          <TabList
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            overflow="hidden"
            p={0}
          >
            {["General", "Media", "AI"].map((tab, index) => (
              <Tab
              marginBottom={0}
                k key={tab}
                fontSize="14px"
                fontWeight="bold"
                flex={1} // Tabs take equal space
                _selected={{
                  bg: "purple.400", // Purple color for selected tab
                  color: "white",
                  borderRadius: "full", // Full radius when selected
                }}
                _hover={{ bg: "purple.300", color: "white" }}
                // px={4}
                // py={2}
                bg={"gray.100"} // Alternate tab colors
                borderRadius="full" // Full radius for all tabs
                mr={index < 2 ? 2 : 0} // Adds a margin between tabs
              >
                {tab}
              </Tab>
            ))}
          </TabList>
        </Tabs>


      </Box>

      {/* Main Content Grid */}
      <Box
        width="100%"
        mt={4}
        display="flex"
        gap={4}
        flexDirection={{ base: "column", md: "row" }} // Column for mobile, row for desktop
        justifyContent="space-between"
      >
        {/* Camera Info Section */}
        <Box
          display="flex"
          flexDirection="column"
          width={{ base: "100%", md: "30%" }}
          p={4} // Padding for better spacing
        >
          <VStack align="start" spacing={3} lineHeight={1.6}>
            {renderCameraInfo()}
          </VStack>
        </Box>

        {/* Divider */}
        <div className={styles.customDivider}></div>

        {/* Tabs Section */}
        <Box width={{ base: "100%", md: "70%" }} p={4}>
          {/* Tab container */}
          <Tabs
            variant="unstyled"
            onChange={(index) =>
              setActiveTab(["General", "Media", "AI"][index])
            }
          >
            <Box display={{ base: "none", md: "block" }}>
              <TabList
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                overflow="hidden"
              >
                {["General", "Media", "AI"].map((tab, index) => (
                  <Tab
                    key={tab}
                    fontSize="14px"
                    fontWeight="bold"
                    flex={1} // Tabs take equal space
                    _selected={{
                      bg: "purple.400", // Purple color for selected tab
                      color: "white",
                      borderRadius: "full", // Full radius when selected
                    }}
                    _hover={{ bg: "purple.300", color: "white" }}
                    px={4}
                    py={2}
                    bg={"gray.100"} // Alternate tab colors
                    borderRadius="full" // Full radius for all tabs
                    mr={index < 2 ? 2 : 0} // Adds a margin between tabs
                  >
                    {tab}
                  </Tab>
                ))}
              </TabList>
            </Box>

            <TabPanels>
              {["General", "Media", "AI"].map((tab) => (
                <TabPanel key={tab} p={4}>
                  {renderTabContent(tab)}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      </Box>

      {/* Action Buttons */}
      <HStack spacing={4} mt={6} justifyContent="flex-end">
        <Button
          colorScheme="gray"
          variant="outline"
          onClick={onClose}
          size="md"
          borderColor="black"
          color="black"
          _hover={{ bg: "blackAlpha.100" }}
          px={4}
        >
          Close
        </Button>
        <Button colorScheme="purple" onClick={handleSave} size="md">
          Save
        </Button>
      </HStack>
    </Box>
  );
}

export default Settings;