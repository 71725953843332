import React, { useEffect, useRef, useState } from 'react';
import JessibucaPlayer from 'react-jessibuca';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Image, Button, ModalFooter, useDisclosure } from '@chakra-ui/react';
import styles from './Dashboard.module.css';
import axios from 'axios';
import Controls from '../../pages/Dashboard/DeviceView/Controls';
import ImageMask from './ImageMask';

const Player = ({ device, playUrl, className, style, showControls, width, height }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const jessibucaRef = useRef(null);
  const containerRef = useRef(null);
  const playerRef = useRef(null); // For JessibucaPlayer reference
  const [error, setError] = useState(null);
  const showOperateBtns = true;
  const forceNoOffscreen = false;
  const { isOpen, onOpen, onClose } = useDisclosure();  // Initialize useDisclosure to manage modal state

  const [screenshotUrl, setScreenshotUrl] = useState(null); // State to hold the screenshot URL
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  useEffect(() => {
    // Initialize Jessibuca player on mount if URL contains "hdl"
    if (playUrl && playUrl.includes("hdl")) {
      create();
    }

    // Cleanup Jessibuca instance on unmount
    return () => {
      destroy();
    };
  }, []);

  useEffect(() => {
    const handlePlayUrlChange = async () => {
      if (jessibucaRef.current) {
        await destroy(); // Destroy the existing instance first
      }
      if (playUrl) {
        if (playUrl.includes("hdl")) {
          create();
          play();
        }
      }
    };

    handlePlayUrlChange();
  }, [playUrl]);

  const create = () => {
    if (!containerRef.current) return;

    jessibucaRef.current = new window.JessibucaPro({
      container: containerRef.current,
      decoder: '/js/decoder-pro.js',
      useMse: true,
      videoBuffer: 0.2, // Buffer length
      isResize: false,
      text: "ArcisAI",
      loadingText: "Loading",
      debug: false,
      debugLevel: 'debug',
      showBandwidth: showOperateBtns, // Show bandwidth
      operateBtns: {
        ptz: showOperateBtns,
        fullscreen: showOperateBtns,
        screenshot: showOperateBtns,
        play: showOperateBtns,
        audio: showOperateBtns,
        record: showOperateBtns,
      },
      forceNoOffscreen: forceNoOffscreen,
      isNotMute: false,
    });

    if (jessibucaRef.current.on) {

      jessibucaRef.current.on('ptz', (arrow) => {
        // console.log(device.deviceid)
        const ptzParams = {
          '-step': 0,
          '-act': arrow,
          '-speed': 3,
          '-presetNUM': 1,
          deviceId: `${device.deviceid}.torqueverse.dev` // Replace with actual deviceId
        };
        const authHeader = 'Basic ' + btoa(`admin:`);
        const response = axios.post('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/ptz', ptzParams, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader
          }
        });
        //   console.log('PTZ Status:', response.data);
        // console.log('ptz', arrow);
      });
    } else {
      console.warn('JessibucaPro does not support event listeners in this way.');
    }
  };


  const play = () => {
    if (jessibucaRef.current && playUrl) {
      // Play the new video URL
      jessibucaRef.current.play(playUrl);
      setIsPlaying(true);
    }
  };

  const pause = () => {
    if (jessibucaRef.current) {
      jessibucaRef.current.pause();
      setIsPlaying(false);
    }
  };

  const destroy = async () => {
    if (jessibucaRef.current) {
      await jessibucaRef.current.destroy(); // Properly destroy the instance
      jessibucaRef.current = null; // Clear the reference
      setIsPlaying(false);
    }
  };

  const handleFullscreen = async () => {
    try {
      if (jessibucaRef.current) {
        // Assuming setFullscreen does not return a promise
        jessibucaRef.current.setFullscreen(true);
      }
    } catch (error) {
      setError('Fullscreen failed: ' + error.message);
    }
  };

  const handleRecording = async () => {
    try {
      if (jessibucaRef.current) {
        if (isRecording) {
          await jessibucaRef.current.stopRecordAndSave();
          setIsRecording(false);
        } else {
          await jessibucaRef.current.startRecord();
          setIsRecording(true);
        }
      }
    } catch (error) {
      setError('Recording failed: ' + error.message);
    }
  };

  const handleScreenshot = () => {
    try {
      if (jessibucaRef.current) {
        const file = jessibucaRef.current.screenshot('test', 'blob');
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'screenshot.png'; // Set desired filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        console.warn('Player is not initialized');
      }
    } catch (error) {
      console.error('Screenshot failed:', error.message);
      setError('Screenshot failed: ' + error.message);
    }
  };

  // const handleSegmentation = () => {
  //   try {
  //     if (jessibucaRef.current) {
  //       const file = jessibucaRef.current.screenshot('test', 'blob');
  //       const url = URL.createObjectURL(file);
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.download = 'screenshot.png'; // Set desired filename
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       URL.revokeObjectURL(url);
  //     } else {
  //       console.warn('Player is not initialized');
  //     }
  //   } catch (error) {
  //     console.error('Screenshot failed:', error.message);
  //     setError('Screenshot failed: ' + error.message);
  //   }
  // }

  const handleSegmentation = () => {
    try {
      if (jessibucaRef.current) {
        const file = jessibucaRef.current.screenshot('test', 'blob'); // Capture the screenshot as a Blob
        const url = URL.createObjectURL(file); // Create a URL for the Blob
        console.log(" screenshot url :: ",url)
        setScreenshotUrl(url); // Set the screenshot URL in state
        setIsModalOpen(true); // Open the modal
        onOpen()
      } else {
        console.warn('Player is not initialized');
      }
    } catch (error) {
      console.error('Screenshot failed:', error.message);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    if (screenshotUrl) {
      URL.revokeObjectURL(screenshotUrl); // Cleanup the object URL
      setScreenshotUrl(null); // Clear the screenshot URL from state
    }
  };

  const handleSend = () => {
    // Your send logic here
    console.log('Image sent:', screenshotUrl);
    closeModal(); // Optionally close the modal after sending
  };

  return (
    <>

      {playUrl && playUrl.includes("hdl") ? (
        // Render JessibucaPro style={{ display: "flex", justifyContent: "center" }}
        <div className="container-shell"  >
          <div
            id="container"
            ref={containerRef}
            className={className}
            style={style}
            
          ></div>
        </div>
      ) : playUrl && playUrl.includes("record") ? (
        <div style={{ position: 'relative', width, height }}>
          <video
            style={style}
            // controls
            autoPlay
            muted
            src={playUrl}
          />
        </div>

      ) : (
        // Render JessibucaPlayer as a fallback for other URLs
        <JessibucaPlayer
          ref={containerRef}
          // style={{ width: '1220px', height: '720px' }}
          style={style}
          className={styles.deviceImage}
          controls={true}
          loadingText="loading"
          src={playUrl}
          decoder="/decoder.js"
        />

      )}

      {showControls && (
        <Controls
          device={device}
          onFullscreen={handleFullscreen}
          onScreenshot={handleScreenshot}
          onRecording={handleRecording}
          isRecording={isRecording}
          onSegment={handleSegmentation}
        />
      )}
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Screenshot with Segmentation</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      {/* Render the ImageMask component inside the modal */}
      <div style={{ position: 'relative', width: '100%' }}>
        <ImageMask screenshotUrl={screenshotUrl} device={device} />
      </div>
        
      {/* Button after ImageMask */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
      </div>
    </ModalBody>
  </ModalContent>
</Modal>

    </>
  );
};

export default Player;
