import React, { useState } from 'react';
import styles from '../Settings.module.css';
import { IoSettingsOutline, IoWifi } from "react-icons/io5";
import { LuBadgeHelp } from "react-icons/lu";
import { MdContentCopy } from "react-icons/md";
import Video_Setup from './Video_Setup';
import Image_Setup from './Image_Setup';
import ISP_Setup from './ISP_Setup';
import PrivacyMask_Setup from './PrivacyMask_Setup';
import OSD_Setup from './OSD_Setup';
import { Box, VStack } from '@chakra-ui/react';
import MobileViewMediaSettings from './MobileViewMediaSettings';


function Media({selectedMedia, deviceId, onSave }) {



    return(
      <Box
      width="100%" // Ensures the box takes full width
      p={{ base: 2, md: 4 }} // Responsive padding for better spacing
      bg="white" // Background color for better contrast
      borderRadius="md" // Rounded corners for aesthetics
      boxShadow="sm" // Subtle shadow for depth
    >
      <VStack spacing={4} align="start" display={{ base: 'none', md: 'block' }} flexDirection="column"> {/* Use VStack for vertical alignment */}
        {/* {selectedMedia === 'Video Setting' && <Video_Setup deviceId={deviceId} onSave={onSave} />} */}
        {selectedMedia === 'Image Setting' && <Image_Setup deviceId={deviceId} onSave={onSave} />}
        {/* {selectedMedia === 'ISP' && <ISP_Setup deviceId={deviceId} onSave={onSave} />}
        {selectedMedia === 'Privacy Mask' && <PrivacyMask_Setup deviceId={deviceId} onSave={onSave} />}
        {selectedMedia === 'OSD' && <OSD_Setup deviceId={deviceId} onSave={onSave} />} */}
      </VStack>
      
      <Box display={{ md: 'none' }}>
        <MobileViewMediaSettings deviceId={deviceId} onSave={onSave} />
      </Box>
    </Box>
    
    );

}

export default Media;