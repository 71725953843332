import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React, { useRef } from 'react';
import Player from '../Player/Player';
// import ReactPlayer from 'react-player';

const MapModel = ({ isOpen,  url,  onClose,  }) => {
    const playerRef = useRef(null);
  if (!isOpen) return null;

  // const iconColor = status === 'RUNNING' ? '🟢' : '🔴';

  return (
    <div className="modal">
      <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              {/* <ModalHeader>{deviceId}</ModalHeader> */}
              <ModalCloseButton />
              <ModalBody>
                {/* {selectedCamera && ( */}
                  <>
                    <ModalHeader>Play</ModalHeader>  {/*  {iconColor} */}
                    {/* <Player
            // device={device}
            // showControls={true}
            // style={getResponsivePlayerStyle()}
            // playUrl={url}
            // ref={playerRef}
            // onPlay={handlePlay}
            // onPause={handlePause}
            // onDestroy={handleDestroy}
            // onFullscreen={handleFullscreen}
            // onScreenshot={handleScreenshot}
            // onAudioToggle={handleAudioToggle}
            // onRecording={handleRecording}
          /> */}
                    {/* <ReactPlayer
                      url={flvUrl}
                      playing={true}
                      controls={true}
                      width="100%"
                      height="100%"
                    /> */}
                    {/* <Flex justifyContent="space-between" mt={4} mb={4}>
                      <Button colorScheme="blue" mt={4} onClick={() => handleGetData(deviceId, 'flip')}>
                        Flip  <LuFlipVertical2 />
                      </Button>
                      <Button colorScheme="blue" mt={4} onClick={() => handleGetData(deviceId, 'mirror')}>
                        Mirror  <LuFlipHorizontal2 />
                      </Button>
                    </Flex> */}
                  </>
                {/* )} */}
              </ModalBody>
              <ModalFooter fontSize={'small'} display='flex' justifyContent='flex-start' pt={1}>
                {/* {location} */}
                {/* <Button colorScheme="blue" mr={3}>
                  Close
                </Button> */}
              </ModalFooter>
            </ModalContent>
          </Modal>
    </div>
  );
};

export default MapModel;