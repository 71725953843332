import React, { useEffect } from "react";
import styles from './MultiScreenDesktop.module.css';
import Player from "../../components/Player/Player";
// import JessibucaPlayer from "react-jessibuca"

function ScreenCard({ image, title, deviceid, plan }) {
  useEffect(() => {
    const changeHoverText = () => {
      const elements = document.querySelectorAll('.icon-title-tips .icon-title');
      elements.forEach(element => {
        if (element.textContent === '暂停') {
          element.textContent = 'Pause';
        }
        if (element.textContent === '截图') {
          element.textContent = 'ScreenSort';
        }
        if (element.textContent === '录制') {
          element.textContent = 'Video';
        }
        if (element.textContent === '全屏') {
          element.textContent = 'FullScreen';
        }
        if (element.textContent === '退出全屏') {
          element.textContent = 'Exit';
        }
        if (element.textContent === '播放') {
          element.textContent = 'Play';
        }
        if (element.textContent === '停止录制') {
          element.textContent = 'Stop Recording';
        }
        if (element.textContent === "操作盘激活") {
          element.textContent = "PTZ";
        }
        if (element.textContent === "操作盘") {
          element.textContent = "PTZ";
        }
        // 退出全屏
      });
    };

    // Create a MutationObserver to monitor changes in the DOM
    const observer = new MutationObserver(() => {
      changeHoverText();
    });

    // Observe the body for changes in its child elements
    observer.observe(document.body, { childList: true, subtree: true });

    // Initial run to catch elements already in the DOM
    changeHoverText();

    // Cleanup the observer when the component is unmounted
    return () => observer.disconnect();
  }, []);
  return (
    <div className={styles.screenCard}>
      <div className={styles.imageWrapper}>
        <Player
          playUrl={plan === "LIVE" ? `https://${deviceid}.torqueverse.dev/flv/live_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==` :
            `https://media2.arcisai.io:8443/hdl/${plan}/RTSP-${deviceid}.flv`
          }
          style={{width:'320px',height:'220px'}}
          className={styles.imageWrapper}
        />
        
        <span className={styles.connectionStatus}>connected</span>
      </div>
      <div className={styles.cardFooter}>
        <h2 className={styles.cardTitle}>{title}</h2>

      </div>
    </div>
  );
}

export default ScreenCard;